<!-- <template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="spinner-border spinner-border-lg loader" v-if="loading"></div>
                <div v-else>
                    <div class="card kx-card table-responsive">
                        <div class="row">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <button class="btn btn-primary float-end" @click="openModal()">Add
                                    Physiotherapy</button>
                            </div>
                        </div>

                        <table class="table align-middle table-bordered text-center mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Mode</th>
                                    <th scope="col">Done</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in PhysiotherapyData" :key="item.id">
                                    <td>{{ formatDate(item.date) }}</td>
                                    <td v-if="item.editable">
                                        <VueTimepicker format="HH:mm" v-model="item.editedTime" />
                                    </td>
                                    <td v-else>{{ formatTime(item.time) }}</td>
                                    <td v-if="item.editable">
                                        <select class="form-select" aria-label="mode" v-model="item.editedMode">
                                            <option v-for="option in Mode_Data" :value="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </td>
                                    <td v-else>{{ item.mode }}</td>
                                    <td v-if="item.editable">
                                        <select class="form-select" aria-label="done" v-model="item.editedStatus">
                                            <option v-for="option in followup_statuses" :value="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </td>
                                    <td v-else>{{ item.status }}</td>
                                    <td v-if="item.editable">
                                        <input type="file" class="form-control"
                                            accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/webp"
                                            @change="handleImageUpload1(item, $event)" />
                                        <img v-if="item.image" :src="getImageUrl(item)"
                                            alt="Image Preview"
                                            style="max-width: 100px; max-height: 100px; margin-top: 10px;">

                                    </td>
                                    <td v-else>
                                        <button class="btn btn-primary" @click="openImageModal(item)" :disabled="!item.hasImage">View</button>
                                    </td>
                                    <td v-if="item.editable">
                                        <input type="text" class="form-input" v-model="item.editedRemark" />
                                    </td>
                                    <td v-else>{{ item.remark }}</td>
                                    <td>
                                        <button v-if="!item.editable" class="btn btn-info" @click="toggleEdit(item)">
                                            Edit
                                        </button>
                                        <button v-else class="btn btn-success" @click="saveChanges(item)">
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div v-if="showImageModal" class="modal" @click.self="closeImageModal">
        <div class="modal-content2">
            <span class="close" @click="closeImageModal">&times;</span>
            <h2 class="text-center" style="font-weight: bold; color:green;">Image Preview</h2>
            <div class="mt-2">
                <img v-if="currentImage" :src="currentImage" style="max-width: 100%; max-height: 80vh;" />
            </div>
        </div>
    </div>



    <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <h2 class="text-center" style="font-weight: bold; color:green; ">{{ modalTitle }}</h2>
            <div class="assessment_data row mt-2">
                <div class="col-sm-12">
                    <div class="row">
                        <div>
                            <label for="xray_diagnosis_left" class="form-text-label kx-label-primary"><strong> Add
                                    Physiotherapy </strong></label>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label for="date" class="form-label kx-label-secondary">Date</label>
                                    <VueDatePicker id="date" v-model="AddData.date" format="dd/MM/yyyy"></VueDatePicker>
                                </div>
                                <div class="col-sm-6">
                                    <label for="time" class="form-text-label kx-label-secondary">Time</label>
                                    <VueTimepicker format="HH:mm" v-model="AddData.time" class="time-field" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="deformity_left" class="form-text-label kx-label-secondary">Mode</label>
                                    <select class="form-select" aria-label="mode" v-model="AddData.mode">
                                        <option v-for="option in Mode_Data" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="status" class="form-text-label kx-label-secondary">Status</label>
                                    <select class="form-select" aria-label="status" v-model="AddData.status">
                                        <option v-for="option in followup_statuses" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="remark" class="form-text-label kx-label-secondary">Remark</label>
                                    <textarea class="form-control" id="remark" v-model="AddData.remark" rows="3"
                                        placeholder="Remark"></textarea>
                                </div>
                                <div class="col-sm-6">
                                    <label for="image" class="form-text-label kx-label-secondary">Upload Image</label>
                                    <input type="file" class="form-control"
                                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/webp"
                                        @change="handleImageUpload($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <button class="btn btn-primary" @click="saveData" :disabled="!isFormValid"
                    style="background-color: #007bff; float: right;" @mouseover="changeColor"
                    @mouseleave="resetColor">Save</button>
            </div>
        </div>

        <div v-if="showLoadingModal" class="model-loading" @click.self="closeLoadingModal">
            <div class="modal-loading-content text-center">
                <div class="spinner-border spinner-border-lg loader"></div>
                <p>Loading...</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import 'vue3-timepicker/dist/VueTimepicker.css';
import VueTimepicker from 'vue3-timepicker';

export default {
    name: "Physiotherapy",
    props: {
        msg: String,
    },
    data() {
        return {
            AddData: {
                knee_lead: this.$route.params.id,
                date: "",
                time: "",
                mode: "",
                status: "",
                remark: "",
                filedata: "",
                filename: ""
            },

            perPage: 10,
            currentPage: 1,
            PhysiotherapyData: [],
            loading: false,
            followup_statuses: ["Completed", "Not Completed"],
            Mode_Data: ["Online", "Video"],
            Physiotherapy_Details: [],
            showModal: false,
            showLoadingModal: false,
            showImageModal: false,
            currentImage: null,
            baseUrl: process.env.VUE_APP_BASE_URL || 'https://lead.kneexpert.in',
        };
    },
    components: {
        VueTimepicker,
    },
    computed: {
        isFormValid() {
            return this.AddData.date !== "" && this.AddData.time !== "";
        },
    },
    methods: {


        getImageUrl(item) {
            // Determine base URL dynamically
            const baseImageUrl = this.baseUrl.endsWith('/') ? this.baseUrl.slice(0, -1) : this.baseUrl;
            return `${baseImageUrl}${item.image}`;
        },


        openImageModal(item) {
            if (item.image) {
                const baseUrl = process.env.VUE_APP_BASE_URL || 'https://lead.kneexpert.in';
                const imageUrl = `${baseUrl}${item.image}`;
                this.currentImage = imageUrl;
                this.showImageModal = true;
            } else {
                item.hasImage = false;
            }
        },

        closeImageModal() {
            this.showImageModal = false;
            this.currentImage = null;
        },


        handleImageUpload1(item, event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    item.image = e.target.result;
                    item.filedata = e.target.result;
                    item.filename = file.name;
                };
                reader.readAsDataURL(file);
            }
        },


        saveData() {
            const formattedDate = new Date(this.AddData.date).toISOString().split('T')[0];
            const formattedTime = this.formatTimeForAPI(this.AddData.time);

            const requestData = {
                knee_lead: this.AddData.knee_lead,
                date: formattedDate,
                time: formattedTime,
                mode: this.AddData.mode,
                status: this.AddData.status,
                remark: this.AddData.remark,
                filename: this.AddData.filename, // Include file_name
                filedata: this.AddData.filedata,
            };
            this.showLoadingModal = true;

            axios
                .post("knee_lead.api.create_knee_lead_physiotherapy", requestData)
                .then(response => {
                    this.showLoadingModal = false;
                    if (response.data.status_code === 200) {
                        this.modalTitle = response.data.message;
                        setTimeout(() => {
                            this.closeModal();
                            this.get_PhysiotherapyData();
                        }, 1500);
                    }
                })
                .catch(error => {
                    this.showLoadingModal = false;
                    console.error("Error saving data:", error);
                });
        },

        openModal() {
            this.showModal = true;
        },
        closeLoadingModal() {
            this.showLoadingModal = false;
        },
        closeModal() {
            this.modalTitle = "";
            this.showModal = false;
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.AddData.filedata = e.target.result;
                    this.AddData.filename = file.name;
                };
                reader.readAsDataURL(file);
            }
        },

        async toggleEdit(item) {
            item.editable = !item.editable;
            this.PhysiotherapyData.forEach(otherItem => {
                if (otherItem !== item) {
                    otherItem.editable = false;
                }
            });
            item.editedTime = item.time;
            item.editedMode = item.mode;
            item.editedStatus = item.status;
            item.editedRemark = item.remark;
        },
        async saveChanges(item) {
            const formattedTime = this.formatTimeForAPI(item.editedTime);

            const data = {
                name: item.name,
                date: item.date,
                time: formattedTime,
                mode: item.editedMode,
                status: item.editedStatus,
                remark: item.editedRemark,
                filedata: item.filedata,
                filename: item.filename
            };

            try {
                await axios.post("knee_lead.api.update_knee_lead_physiotherapy", data);
                item.editable = false;
                await this.get_PhysiotherapyData();
            } catch (error) {
                console.error("Error updating follow-up status:", error);
            }
        },
        formatDate(date) {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return new Date(date).toLocaleDateString('en-GB', options).replace(/\//g, '-');
        },
        formatTime(time) {
            if (!time || typeof time !== 'string') {
                return '';
            }
            const [hours, minutes] = time.split(':');
            const dummyDate = new Date('1970-01-01T00:00:00');
            dummyDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));
            const options = { hour: '2-digit', minute: '2-digit', hour12: true };
            return dummyDate.toLocaleTimeString('en-US', options);
        },
        formatTimeForAPI(time) {
            if (!time) {
                return '';
            }
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}:00`;
        },
        async get_PhysiotherapyData() {
            try {
                this.loading = true;
                const response = await axios.get("knee_lead.api.get_knee_lead_physiotherapy", {
                    params: {
                        knee_lead: this.$route.params.id,
                    },
                });
                this.PhysiotherapyData = response.data.data.map(item => {
                    item.editable = false;
                    item.editedTime = item.time;
                    item.editedMode = item.mode;
                    item.editedStatus = item.status;
                    item.editedRemark = item.remark;
                    item.editedImage = item.image;
                    item.hasImage = !!item.image; 
                    return item;
                });
            } catch (error) {
                console.error("Error fetching Physiotherapy data:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.get_PhysiotherapyData();
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    padding-top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 60%;
    width: 100%;
    position: relative;
}

.modal-content2 {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}

.model-loading {
    position: fixed;
    top: 0;
    padding-top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.time-field {
    width: 100%;
}

.modal-loading-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    position: relative;
}
</style> -->





<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="spinner-border spinner-border-lg loader" v-if="loading"></div>
                <div v-else>
                    <div class="card kx-card table-responsive">
                        <div class="row">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6">
                                <button class="btn btn-primary float-end" @click="openModal()">Add
                                    Physiotherapy</button>
                            </div>
                        </div>

                        <table class="table align-middle table-bordered text-center mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Mode</th>
                                    <th scope="col">Done</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Remark</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in PhysiotherapyData" :key="item.id">
                                    <td>{{ formatDate(item.date) }}</td>
                                    <td v-if="item.editable">
                                        <VueTimepicker format="HH:mm" v-model="item.editedTime" />
                                    </td>
                                    <td v-else>{{ formatTime(item.time) }}</td>
                                    <td v-if="item.editable">
                                        <select class="form-select" aria-label="mode" v-model="item.editedMode">
                                            <option v-for="option in Mode_Data" :value="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </td>
                                    <td v-else>{{ item.mode }}</td>
                                    <td v-if="item.editable">
                                        <select class="form-select" aria-label="done" v-model="item.editedStatus">
                                            <option v-for="option in followup_statuses" :value="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </td>
                                    <td v-else>{{ item.status }}</td>
                                    <td v-if="item.editable">
                                        <input type="file" class="form-control"
                                            accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/webp"
                                            @change="handleImageUpload1(item, $event)" />
                                        <img v-if="item.image" :src="getImageUrl(item)" alt="Image Preview"
                                            style="max-width: 100px; max-height: 100px; margin-top: 10px;">

                                    </td>
                                    <td v-else>
                                        <button class="btn btn-primary" @click="openImageModal(item)"
                                            :disabled="!item.hasImage">View</button>
                                    </td>
                                    <td v-if="item.editable">
                                        <input type="text" class="form-input" v-model="item.editedRemark" />
                                    </td>
                                    <td v-else>{{ item.remark }}</td>
                                    <td>
                                        <button v-if="!item.editable" class="btn btn-info" @click="toggleEdit(item)">
                                            Edit
                                        </button>
                                        <button v-else class="btn btn-success" @click="saveChanges(item)">
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div v-if="showImageModal" class="modal" @click.self="closeImageModal">
        <div class="modal-content2">
            <span class="close" @click="closeImageModal">&times;</span>
            <h2 class="text-center" style="font-weight: bold; color:green;">Image Preview</h2>
            <div class="mt-2">
                <img v-if="currentImage" :src="currentImage" style="max-width: 100%; max-height: 80vh;" />
            </div>
        </div>
    </div>



    <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <h2 class="text-center" style="font-weight: bold; color:green; ">{{ modalTitle }}</h2>
            <div class="assessment_data row mt-2">
                <div class="col-sm-12">
                    <div class="row">
                        <div>
                            <label for="xray_diagnosis_left" class="form-text-label kx-label-primary"><strong> Add
                                    Physiotherapy </strong></label>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label for="date" class="form-label kx-label-secondary">Date</label>
                                    <VueDatePicker id="date" v-model="AddData.date" format="dd/MM/yyyy"></VueDatePicker>
                                </div>
                                <div class="col-sm-6">
                                    <label for="time" class="form-text-label kx-label-secondary">Time</label>
                                    <VueTimepicker format="HH:mm" v-model="AddData.time" class="time-field" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="deformity_left" class="form-text-label kx-label-secondary">Mode</label>
                                    <select class="form-select" aria-label="mode" v-model="AddData.mode">
                                        <option v-for="option in Mode_Data" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="status" class="form-text-label kx-label-secondary">Status</label>
                                    <select class="form-select" aria-label="status" v-model="AddData.status">
                                        <option v-for="option in followup_statuses" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="remark" class="form-text-label kx-label-secondary">Remark</label>
                                    <textarea class="form-control" id="remark" v-model="AddData.remark" rows="3"
                                        placeholder="Remark"></textarea>
                                </div>
                                <div class="col-sm-6">
                                    <label for="image" class="form-text-label kx-label-secondary">Upload Image</label>
                                    <input type="file" class="form-control"
                                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/webp"
                                        @change="handleImageUpload($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <button class="btn btn-primary" @click="saveData" :disabled="!isFormValid"
                    style="background-color: #007bff; float: right;" @mouseover="changeColor"
                    @mouseleave="resetColor">Save</button>
            </div>
        </div>

        <div v-if="showLoadingModal" class="model-loading" @click.self="closeLoadingModal">
            <div class="modal-loading-content text-center">
                <div class="spinner-border spinner-border-lg loader"></div>
                <p>Loading...</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import 'vue3-timepicker/dist/VueTimepicker.css';
import VueTimepicker from 'vue3-timepicker';

export default {
    name: "Physiotherapy",
    props: {
        msg: String,
    },
    data() {
        return {
            AddData: {
                knee_lead: this.$route.params.id,
                date: "",
                time: "",
                mode: "",
                status: "",
                remark: "",
                filedata: "",
                filename: ""
            },

            perPage: 10,
            currentPage: 1,
            PhysiotherapyData: [],
            loading: false,
            followup_statuses: ["Completed", "Not Completed"],
            Mode_Data: ["Online", "Video"],
            Physiotherapy_Details: [],
            showModal: false,
            showLoadingModal: false,
            showImageModal: false,
            currentImage: null,
            baseUrl: process.env.VUE_APP_BASE_URL || 'https://lead.kneexpert.in',
        };
    },
    components: {
        VueTimepicker,
    },
    computed: {
        isFormValid() {
            return this.AddData.date !== "" && this.AddData.time !== "";
        },
    },
    methods: {


        getImageUrl(item) {
            // Determine base URL dynamically
            const baseImageUrl = this.baseUrl.endsWith('/') ? this.baseUrl.slice(0, -1) : this.baseUrl;
            return `${baseImageUrl}${item.image}`;
        },


        openImageModal(item) {
            if (item.image) {
                const baseUrl = process.env.VUE_APP_BASE_URL || 'https://lead.kneexpert.in';
                const imageUrl = `${baseUrl}${item.image}`;
                this.currentImage = imageUrl;
                this.showImageModal = true;
            } else {
                item.hasImage = false;
            }
        },

        closeImageModal() {
            this.showImageModal = false;
            this.currentImage = null;
        },


        handleImageUpload1(item, event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    item.image = e.target.result;
                    item.filedata = e.target.result;
                    item.filename = file.name;
                };
                reader.readAsDataURL(file);
            }
        },


        saveData() {
            const formattedDate = new Date(this.AddData.date).toISOString().split('T')[0];
            const formattedTime = this.formatTimeForAPI(this.AddData.time);

            const requestData = {
                knee_lead: this.AddData.knee_lead,
                date: formattedDate,
                time: formattedTime,
                mode: this.AddData.mode,
                status: this.AddData.status,
                remark: this.AddData.remark,
                filename: this.AddData.filename, // Include file_name
                filedata: this.AddData.filedata,
            };
            this.showLoadingModal = true;

            axios
                .post("knee_lead.api.create_knee_lead_physiotherapy", requestData)
                .then(response => {
                    this.showLoadingModal = false;
                    if (response.data.status_code === 200) {
                        this.modalTitle = response.data.message;
                        setTimeout(() => {
                            this.closeModal();
                            this.get_PhysiotherapyData();
                        }, 1500);
                    }
                })
                .catch(error => {
                    this.showLoadingModal = false;
                    console.error("Error saving data:", error);
                });
        },

        openModal() {
            this.showModal = true;
        },
        closeLoadingModal() {
            this.showLoadingModal = false;
        },
        closeModal() {
            this.modalTitle = "";
            this.showModal = false;
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.AddData.filedata = e.target.result;
                    this.AddData.filename = file.name;
                };
                reader.readAsDataURL(file);
            }
        },

        async toggleEdit(item) {
            item.editable = !item.editable;
            // Ensure only one item is editable at a time
            this.PhysiotherapyData.forEach(otherItem => {
                if (otherItem !== item) {
                    otherItem.editable = false;
                }
            });

            // Set edited fields only when entering edit mode
            if (item.editable) {
                item.editedTime = item.time ? item.time : '';  // Initialize editedTime only if time is present
                item.editedMode = item.mode;
                item.editedStatus = item.status;
                item.editedRemark = item.remark;
            }
        },

        async saveChanges(item) {
            // Capture the editedTime value correctly from VueTimepicker
            const timeValue = typeof item.editedTime === 'string' ? item.editedTime : this.convertTimepickerToTimeString(item.editedTime);
            const formattedTime = this.formatTimeForAPI(timeValue);

            // Prepare payload data
            const data = {
                name: item.name,
                date: item.date,
                time: formattedTime,  // Send the correct time value
                mode: item.editedMode,
                status: item.editedStatus,
                remark: item.editedRemark,
                filedata: item.filedata,
                filename: item.filename
            };

            try {
                await axios.post("knee_lead.api.update_knee_lead_physiotherapy", data);
                item.editable = false;
                await this.get_PhysiotherapyData();
            } catch (error) {
                console.error("Error updating follow-up status:", error);
            }
        },




        formatDate(date) {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return new Date(date).toLocaleDateString('en-GB', options).replace(/\//g, '-');
        },
        formatTime(time) {
            if (!time || typeof time !== 'string') {
                return '';
            }
            const [hours, minutes] = time.split(':');
            const dummyDate = new Date('1970-01-01T00:00:00');
            dummyDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));
            const options = { hour: '2-digit', minute: '2-digit', hour12: true };
            return dummyDate.toLocaleTimeString('en-US', options);
        },
        convertTimepickerToTimeString(timeValue) {
            if (timeValue instanceof Date) {
                // Convert Date object to HH:mm format
                const hours = timeValue.getHours().toString().padStart(2, '0');
                const minutes = timeValue.getMinutes().toString().padStart(2, '0');
                return `${hours}:${minutes}`;
            }
            return timeValue;  // If already in HH:mm format, return as is
        },

        formatTimeForAPI(time) {
            if (!time || typeof time !== 'string') {
                return '00:00:00';  // If invalid, return fallback value (shouldn't reach here)
            }
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}:00`;
        },

        async get_PhysiotherapyData() {
            try {
                this.loading = true;
                const response = await axios.get("knee_lead.api.get_knee_lead_physiotherapy", {
                    params: {
                        knee_lead: this.$route.params.id,
                    },
                });
                this.PhysiotherapyData = response.data.data.map(item => {
                    item.editable = false;
                    item.editedTime = item.time;
                    item.editedMode = item.mode;
                    item.editedStatus = item.status;
                    item.editedRemark = item.remark;
                    item.editedImage = item.image;
                    item.hasImage = !!item.image;
                    return item;
                });
            } catch (error) {
                console.error("Error fetching Physiotherapy data:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.get_PhysiotherapyData();
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    padding-top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 60%;
    width: 100%;
    position: relative;
}

.modal-content2 {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}

.model-loading {
    position: fixed;
    top: 0;
    padding-top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.time-field {
    width: 100%;
}

.modal-loading-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    position: relative;
}
</style>
