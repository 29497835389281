<template>
  <div id="content">
    <SideBar></SideBar>
    <Header></Header>

    <!-- Button Container for Filters -->
    <div class="button-container">
      <button
        @click="showAllClaims"
        class="button"
        :class="{ active: activeTab === 'allClaims' }"
        :disabled="loading"
      >
        <span v-if="loading && activeTab === 'allClaims'">Loading...</span>
        <span v-else>All Claims</span>
      </button>
      <button
        @click="showApproved"
        class="button"
        :class="{ active: activeTab === 'approved' }"
        :disabled="loading"
      >
        <span v-if="loading && activeTab === 'approved'">Loading...</span>
        <span v-else>Approved</span>
      </button>
      <button
        @click="showPending"
        class="button"
        :class="{ active: activeTab === 'pending' }"
        :disabled="loading"
      >
        <span v-if="loading && activeTab === 'pending'">Loading...</span>
        <span v-else>Pending</span>
      </button>
    </div>

    <!-- Subheading and Select Dropdown for Claim Type -->
    <div class="subheading-container">
      <label for="claimType" class="subheading">Claim Type: </label>
      <select
        id="claimType"
        v-model="selectedClaimType"
        @change="fetchClaimsData"
        class="claim-type-select"
        :disabled="loading"
      >
        <option value="">All</option>
        <option value="Video Consultation">Video Consultation</option>
        <option value="OPD visited">OPD Visited</option>
        <option value="PRP Done">PRP Done</option>
      </select>

      <!-- Total Claim Amount Subheading -->
      <span class="total-claim-amount"
        >Total Claim Amount: ₹{{ totalClaimAmount }}</span
      >
    </div>

    <!-- Table Container for Displaying Claims -->
    <div class="table-container" v-if="filteredClaims.length > 0">
      <table class="claims-table">
        <thead>
          <tr>
            <th class="serial-number-column">Sr No.</th>
            <th>Knee Lead</th>
            <th>Claim Type</th>
            <th>Claim Amount (₹)</th>
            <th>Claimed On</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(claim, index) in filteredClaims"
            :key="claim.name"
            class="table-row"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ claim.knee_lead }}</td>
            <td>{{ claim.knee_lead_status }}</td>
            <td>{{ claim.claim_amount }}</td>
            <td>{{ claim.creation }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      <h4 style="text-align: center; margin-top: 40px">No claims available.</h4>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import moment from "moment"; // Import moment.js for date formatting
import SideBar from "@/components/SideBaar.vue";
import Header from "@/components/Header/Header.vue";

export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
      allClaims: [],
      filteredClaims: [],
      activeTab: "allClaims", // Default active tab
      selectedClaimType: "", // Holds the selected claim type for filtering
      loading: false, // Loading state to show spinner or disable buttons
      totalClaimAmount: 0, // Holds the total claim amount
    };
  },
  mounted() {
    this.fetchClaimsData();
  },
  methods: {
    showAllClaims() {
      this.activeTab = "allClaims";
      this.fetchClaimsData();
    },
    showApproved() {
      this.activeTab = "approved";
      this.fetchClaimsData();
    },
    showPending() {
      this.activeTab = "pending";
      this.fetchClaimsData();
    },

    async fetchClaimsData() {
      try {
        this.loading = true; // Set loading to true when the API call starts
        let apiEndpoint =
          "knee_lead.claim.get_my_claims";

        let requestBody = {};

        if (this.activeTab === "approved") {
          requestBody.approved = 1;
        } else if (this.activeTab === "pending") {
          requestBody.approved = 0;
        }

        if (this.selectedClaimType) {
          requestBody.claim_type = this.selectedClaimType;
        }

        const response = await axios.post(apiEndpoint, requestBody);

        

        if (
          response.data.status_code === 200 &&
          response.data.data.claims.length > 0
        ) {
          this.allClaims = response.data.data.claims.map((claim) => {
            return {
              ...claim,
              creation: moment(claim.creation).format("DD-MM-YYYY hh:mm A"),
            };
          });
          this.filteredClaims = [...this.allClaims];
          this.totalClaimAmount = response.data.data.total_claim; // Set the total claim amount
        } else {
          this.allClaims = [];
          this.filteredClaims = [];
          this.totalClaimAmount = 0; // Reset total claim amount
        }
      } catch (error) {
        console.error("Error fetching claims:", error);
        this.allClaims = [];
        this.filteredClaims = [];
        this.totalClaimAmount = 0; // Reset total claim amount in case of error
      } finally {
        this.loading = false; // Set loading to false when the API call is complete
      }
    },
  },
};
</script>
  
  <style scoped>
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  background-color: #2bafcd;
  color: white;
  padding: 12px 20px;
  margin: 5px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.button:hover {
  transform: translateY(-2px);
}

.button:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}

.button.active {
  background-color: green;
  color: white;
}

.subheading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.subheading {
  font-weight: bold;
  margin-right: 10px;
}

.total-claim-amount {
  font-weight: bold;
  margin-left: 20px; /* Space between Claim Type and Total Claim Amount */
  color: #4caf50; /* Green color for the total amount */
}

.claim-type-select {
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.claim-type-select:disabled {
  background-color: #f0f0f0;
}

.table-container {
  margin: 30px auto;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.claims-table {
  width: 100%;
  border-collapse: collapse; /* Ensures cells have a clear border separation */
}

th,
td {
  padding: 12px 15px;
  text-align: center;
  border: 1px solid #ddd; /* Adds border around each cell */
}

th {
  background-color: #2bafcd;
  color: white;
  border: 1px solid #b0c4de; /* Adds border to table header */
}

.table-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-row:nth-child(even) {
  background-color: #f1f1f1;
}

.table-row:hover {
  background-color: #f0f8ff;
}

.serial-number-column {
  width: 60px;
}
</style>
  