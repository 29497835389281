<template>
  <div class="modal-mask" v-if="show">
    <div class="modal-container rounded">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="exampleModalLabel" style="font-weight: bold;">
          Claim
        </h2>

        <button type="button" class="btn-close" data-bs-dismiss="modal-mask" aria-label="Close"
          @click="$emit('close')"></button>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="Group" style="font-weight: bold;">OPD Center *</label>
            <select class="form-select" aria-label="opd_center" v-model="opd_center" required :disabled="loading">
              <option v-for="option in opd_location_options" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">Doctor *</label>
            <input class="form-control" type="text" v-model="doctor" required :disabled="loading"/>
          </div>
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="Group" style="font-weight: bold;">Date of OPD *</label>
            <VueDatePicker 
              id="date_of_opd" 
              auto-apply 
              v-model="date_of_opd" 
              format="dd/MM/yyyy" 
              :disabled="loading" 
              :disabled-dates="disableFutureDates"
            ></VueDatePicker>
          </div>
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">Upload Prescription *</label>
            <input type="file" class="form-control"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/webp"
              @change="handleImageUpload($event)" required :disabled="loading"/>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-5">
        <button class="btn btn-sm btn-primary mt-2" @click="saveData" :disabled="!isFormValid || loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
    <!-- Success Modal -->
    <div class="modal-mask" v-if="showSuccessModal">
    <div class="modal-container rounded">
      <div class="modal-header">
      </div>
      <div class="modal-body" style="display: flex;flex-direction: row; justify-content: center;">
        <h2 class="mt-5 mb-5" :style="{ color: messageColor, fontWeight: 'bold', textAlign: 'center' }">
          {{ message }}
        </h2>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-primary" @click="closeSuccessModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "OpdModel",
  props: {
    msg: String,
    show: false,
    exercise_options: [],
    Knee_lead: ""
  },
  mounted() {
    this.get_opd_list();
  },
  computed: {
    isFormValid() {
      return this.opd_center && this.doctor && this.date_of_opd && this.filedata; // Check if all fields are filled
    },
    messageColor() {
      // Dynamically return the color based on the status code
      return this.status_code === 200 ? 'green' : (this.status_code === 500 ? 'red' : 'black');
    }
  },
  data() {
    return {
      knee_lead: this.$route.params.id,
      opd_center: "",
      doctor: "",
      date_of_opd: null,
      filedata: "",
      filename: "",
      opd_location_options: [],
      showSuccessModal: false,
      message: "",
      status_code: null,
      loading: false,
    };
  },
  methods: {

    disableFutureDates(date) {
  const today = new Date(); // Current date with time
  const selectedDate = new Date(date); // Date from the date picker
  
  // Use only the date part (ignore time) for both today's date and the selected date
  today.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);
  
  // Disable future dates by comparing date only
  return selectedDate > today;
},

    get_opd_list: async function () {
      await axios
        .get("knee_lead.api.get_center_list")
        .then((response) => {
          var res_data = response.data.data[0];
          for (let x in res_data) {
            this.opd_location_options.push(res_data[x].name);
          }
        })
        .catch((error) => { });
    },

    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.filedata = e.target.result;
          this.filename = file.name;
        };
        reader.readAsDataURL(file);
      }
    },

    saveData() {
      if (!this.isFormValid) return;
      this.loading = true;
      const formattedDate = this.date_of_opd ? new Date(this.date_of_opd).toISOString().split('T')[0] : null;
      const requestData = {
        opd_center: this.opd_center,
        doctor: this.doctor,
        date_of_opd: formattedDate,
        knee_lead: this.knee_lead,
        filename: this.filename,
        filedata: this.filedata,
      };
      axios
        .post("knee_lead.claim.create_opd_visited_claim", requestData)
        .then(response => {
          this.status_code = response.data.status_code;  // Track the status code
          this.showSuccessModal = true;
          this.message = response.data.message;
        })
        .catch(error => {
          console.error("Error saving data:", error);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .finally(() => {
          this.loading = false; // Reset loading state after the request completes
        });
    },

    closeSuccessModal() {
      this.showSuccessModal = false;
      window.location.reload(); // Optionally reload the page after closing success modal
    }
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(8, 0, 8, 0.4);
  z-index: 2;
  display: grid;
  place-items: center;
}

.modal-container {
  background: white;
  padding: 1rem;
  width: 80vw;
  max-width: 50%;
}
</style>
