<template>
    <div>
        <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content" style="color: #2E8B57;">
            <span class="close" @click="closeModal">&times;</span>
            <h3 style="font-weight: bold;">Changes saved successfully!</h3>
        </div>
    </div>
        <div class="col-sm-12 card kx-card">
            <div class="row mt-3">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-2 mt-3">
                            <div class="form-check">
                                <input :disabled="complaint_disabled" class="form-check-input" type="checkbox" value=""
                                    v-model="knee_complain" true-value="1" false-value="0" id="Knee" />
                                <label class="form-check-label" for="Knee"> Knee </label>
                            </div>

                            <div class="form-check" v-if="knee_complain == '1'">
                                <input :disabled="complaint_disabled" class="form-check-input" type="checkbox" value=""
                                    v-model="knee_left" true-value="1" false-value="0" id="KneeLeft" />
                                <label class="form-check-label left" for="KneeLeft">
                                    Left
                                </label>
                            </div>

                            <div class="form-check" v-if="knee_complain == '1'">
                                <input :disabled="complaint_disabled" class="form-check-input" type="checkbox" value=""
                                    v-model="knee_right" true-value="1" false-value="0" id="KneeRight" />
                                <label class="form-check-label right" for="KneeRight">
                                    Right
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-2 mt-3">
                            <div class="form-check">
                                <input :disabled="complaint_disabled" class="form-check-input" type="checkbox" value=""
                                    v-model="hip_complain" true-value="1" false-value="0" id="Hip" />
                                <label class="form-check-label" for="Hip"> Hip </label>
                            </div>

                            <div class="form-check" v-if="hip_complain == '1'">
                                <input :disabled="complaint_disabled" class="form-check-input left" type="checkbox"
                                    value="" v-model="hip_left" true-value="1" false-value="0" id="HipLeft" />
                                <label class="form-check-label left" for="HipLeft">
                                    Left
                                </label>
                            </div>

                            <div class="form-check" v-if="hip_complain == '1'">
                                <input :disabled="complaint_disabled" class="form-check-input right" type="checkbox"
                                    value="" v-model="hip_right" true-value="1" false-value="0" id="HipRight" />
                                <label class="form-check-label right" for="HipRight">
                                    Right
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="row" v-if="pain_since">
                                <div class="col mt-3">
                                    <label class="form-select-label kx-label-secondary" for="PainSince">Pain
                                        Since</label>
                                    {{ pain_since }}
                                </div>
                                <div class="col">
                                    <label for="MonthsYears" class="form-text-label kx-label-secondary"></label>
                                    <label for="MonthsYears" class="form-text-label kx-label-secondary"
                                        v-if="pain_since == ''">Years</label>

                                    <input :disabled="complaint_disabled" type="text" class="form-control"
                                        id="MonthsYears" :maxlength="2" @keypress="onlyNumber"
                                        v-model="pain_since_years" />
                                </div>
                            </div>

                            <div class="row" v-else>
                                <div class="col">
                                    <label class="form-select-label kx-label-secondary" for="PainSince">Pain
                                        Since</label><br />
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="inlineRadio1" value="Months" v-model="pain_since" />
                                        <label class="form-check-label" for="inlineRadio1">Months</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="inlineRadio2" value="Years" v-model="pain_since" />
                                        <label class="form-check-label" for="inlineRadio2">Years</label>
                                    </div>
                                    <br />
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="inlineRadio3" value="Days" v-model="pain_since" />
                                        <label class="form-check-label" for="inlineRadio3">Day's</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <label for="MonthsYears" class="form-text-label kx-label-secondary">{{ pain_since
                                        }}</label>
                                    <label for="MonthsYears" class="form-text-label kx-label-secondary"
                                        v-if="pain_since == ''">Years</label>
                                    <input :disabled="complaint_disabled" type="text" class="form-control"
                                        id="MonthsYears" :maxlength="2" v-model="pain_since_years" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label class="form-select-label kx-label-secondary" for="PainLocation">Pain
                                        Location</label>
                                    <select :disabled="complaint_disabled" class="form-select" aria-label="PainLocation"
                                        v-model="pain_location">
                                        <option v-for="option in pain_location_option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label class="form-select-label kx-label-secondary" for="PainLocation">Pain increase
                                        while</label>
                                    <select :disabled="complaint_disabled" class="form-select" aria-label="PainLocation"
                                        v-model="pain_increase_while">
                                        <option v-for="option in pain_increase_while_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label class="form-label kx-label-secondary" for="Height">Height(in Feet)</label>
                                    <input :disabled="complaint_disabled" type="text" pattern="\d*" class="form-control"
                                        id="Height" @keypress="onlyNumber" v-model="patient_height" />
                                </div>
                                <div class="col">
                                    <label class="form-select-label kx-label-secondary" for="Weight">Weight(in
                                        kg)</label>
                                    <input :disabled="complaint_disabled" type="text" pattern="\d*" class="form-control"
                                        id="Weight" @keypress="onlyNumber" v-model="patient_weight" />
                                </div>
                                <div class="col" v-if="patient_bmi">
                                    <label class="form-select-label kx-label-secondary" for="BMI">BMI
                                    </label>
                                    <input disabled type="text" class="form-control" id="BMI" v-model="patient_bmi" />
                                </div>
                                <div class="col" v-else>
                                    <label class="form-select-label kx-label-secondary" for="BMI">BMI
                                    </label>
                                    <button type="button" class="btn btn-light form-control" @click="calculate_bmi">
                                        Calc
                                    </button>
                                    <!-- <input :disabled="complaint_disabled" type="text" class="form-control" id="BMI"   v-model="patient_bmi" > -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <div>
                        <label for="PatientComplaint" class="form-label kx-label-secondary">Complaint</label>
                        <textarea :disabled="complaint_disabled" class="form-control" id="PatientComplaint" rows="5"
                            v-model="pain_complain" placeholder="in words.."></textarea>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-primary" style="background-color: #007bff;" @mouseover="changeColor"
                        @mouseleave="resetColor" @click="saveChanges">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "ComplaintTab",
    props: {
        msg: String,
    },
    mounted: function () {
        this.get_pain_location();
        this.get_pain_increase_while();
        this.get_complaint_by_knee_lead();
    },
    data() {
        return {
            name: "",
            patient_weight: "",
            patient_height: "",
            knee_complain: "",
            knee_left: "",
            knee_right: "",
            hip_complain: "",
            hip_left: "",
            hip_right: "",
            pain_since_years: "",
            pain_since: "",
            pain_since_years: "",
            pain_location: "",
            pain_increase_while: "",
            patient_bmi: "",
            pain_complain: "",
            pain_location_option: [],
            pain_increase_while_options: [],
            complaint_disabled: false,
            showModal: false,
        };
    },
    methods: {
        get_pain_increase_while: async function () {
            await axios
                .get("knee_lead.api.get_pain_increase_while")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.pain_increase_while_options.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },
        get_pain_location: async function () {
            await axios
                .get("knee_lead.api.get_pain_location")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.pain_location_option.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },
        onlyNumber: function ($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        get_complaint_by_knee_lead: async function () {
            await axios
                .get("knee_lead.api.get_complaint_by_knee_lead", {
                    params: {
                        mobile: this.$route.params.id,
                    },
                })
                .then((response) => {
                    this.name = response.data.data[0].name;
                    this.patient_height = response.data.data[0].height;
                    this.patient_weight = response.data.data[0].weight;
                    this.knee_complain = response.data.data[0].knee_complain;
                    this.knee_left = response.data.data[0].knee_left;
                    this.knee_right = response.data.data[0].knee_right;
                    this.hip_complain = response.data.data[0].hip_complain;
                    this.hip_left = response.data.data[0].hip_left;
                    this.hip_right = response.data.data[0].hip_right;
                    this.pain_since_years = response.data.data[0].pain_since_years;
                    this.pain_since = response.data.data[0].pain_since;
                    this.pain_since_years = response.data.data[0].pain_since_years;
                    this.pain_location = response.data.data[0].pain_location;
                    this.pain_increase_while = response.data.data[0].pain_increase_while;
                    this.patient_bmi = response.data.data[0].patient_bmi;
                    this.pain_complain = response.data.data[0].pain_complain;
                })
                .catch((error) => { });
        },
        calculate_bmi: function () {
            let p_height = this.patient_height * 0.3048;

            this.patient_bmi = (this.patient_weight / (p_height * p_height)).toFixed(
                1
            );
        },

        toFeet: function (n) {
            var realFeet = (n * 0.3937) / 12;
            var feet = Math.floor(realFeet);
            var inches = Math.round((realFeet - feet) * 12);
            return feet + "." + inches;
        },

        saveChanges: async function () {
            try {
                let updatedFields = {
                    name: this.$route.params.id,
                    knee_complain: this.knee_complain,
                    knee_left: this.knee_left,
                    knee_right: this.knee_right,
                    hip_complain: this.hip_complain,
                    hip_left: this.hip_left,
                    hip_right: this.hip_right,
                    pain_since_years: this.pain_since_years,
                    pain_since: this.pain_since,
                    pain_since_years: this.pain_since_years,
                    pain_location: this.pain_location,
                    pain_increase_while: this.pain_increase_while,
                    patient_bmi: this.patient_bmi,
                    height: this.patient_height,
                    weight: this.patient_weight,
                    pain_complain: this.pain_complain,
                    // Add more fields as needed
                };
                await this.update_fields(updatedFields);
                this.showModal = true;
                setTimeout(() => {
                    window.location.reload()
                }, 500);
                
            } catch (error) {
                console.error('Error saving changes:', error);
            }
        },

        update_fields: async function (updatedFields) {
            try {
                const response = await axios.post("knee_lead.knee_lead_details.update_knee_lead_complaint_tab", updatedFields);
                this.get_complaint_by_knee_lead();
            } catch (error) {
                console.error('Error updating fields:', error);
                throw error;
            }
        },

        
        closeModal() {
            this.showModal = false;
        },

        changeColor(event) {
            event.target.style.backgroundColor = '#0056b3';
        },
        resetColor(event) {
            event.target.style.backgroundColor = '#007bff';
        }

    },
};
</script>

<style scoped lang="scss">
.modal {
    /* Styles for modal overlay */
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    /* Styles for modal content */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
    /* Ensure the close icon stays in the modal */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}
</style>
