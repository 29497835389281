<template>
    <div class="card kx-card">
        <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); gap: 32px;">
            <div v-for="(assessment, index) in assessmentList" :key="index" style="text-align: center;">
                <!-- Loop through buttons based on count -->
                <button class="btn btn-primary"
                    style="background-color: #2bb0ce; border-radius: 100px; border: none; transition: background-color 0.3s ease;"
                    onmouseover="this.style.backgroundColor='#2b79ce'" onmouseout="this.style.backgroundColor='#2bb0ce'"
                    @click="openModal(assessment)">
                    {{ `Assessment ${index + 1}` }}</button>
                <p style="margin-top: 5px;">
                    {{ assessment.team }}
                </p>
                <p style="font-size: 13px;">
                    {{ formatDate(assessment.creation) }}
                </p>
                <p v-if="assessment.assessed_time" style="font-size: 13px;">
                    {{ formatDate(assessment.assessed_time) }}
                </p>
                <p v-else style="font-size: 13px;">Not Yet Assessed</p>
            </div>
        </div>

        <!-- user assessment form -->
        <div class="row mt-5">
            <div class="col-sm-8">
                <div class="row">
                    <div>
                        <label for="xray_diagnosis_left " class="form-text-label kx-label-primary"><strong> Xray
                                Diagnosis </strong></label>
                        <div class="row" v-if="knee_complain == '1'">
                                <div class="col-sm-6">
                                    <label for="xray_diagnosis_left" class="form-text-label kx-label-secondary">
                                        Diagnosis Left
                                    </label>
                                    <select class="form-select" aria-label="xray_diagnosis_left"
                                        v-model="xray_diagnosis_left">
                                        <option v-for="option in xray_diagnosis_left_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="xray_diagnosis_right"
                                        class="form-text-label kx-label-secondary">Diagnosis Right
                                    </label>
                                    <select class="form-select" aria-label="xray_diagnosis_right"
                                        v-model="xray_diagnosis_right">
                                        <option v-for="option in xray_diagnosis_right_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                            </div>
                            <!-- <div class="col-sm-6 mt-2">
                                <label for="deformity_1"
                                        class="form-text-label kx-label-secondary">Deformity

                                <input class="form-check-input" type="checkbox" value=""
                                        v-model="deformity_1" true-value="1" false-value="0"
                                        id="deformity_1">
                                    </label>
                            </div> -->
                            <!-- <div class="col-sm-6" v-if="deformity_1 === 1">
                                <label for="degree_of_deformity"
                                        class="form-text-label kx-label-secondary">Degree of Deformity
                                    </label>
                                <input type="text" class="form-control form-control-sm"
                                    v-model="degree_of_deformity" placeholder=""
                                    aria-label="degree_of_deformity"  @input="validateNumericInput" aria-describedby="basic-addon2" style="padding:8px;"/>
                            </div> -->
                            <div class="col-sm-6" v-if="deformity_1 === 1">
                                <label for="deformity_left" class="form-text-label kx-label-secondary">
                                    Deformity Left</label>
                                <select class="form-select" aria-label="xray_diagnosis_right" v-model="deformity_left">
                                    <option v-for="option in deformity_left_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6" v-if="deformity_1 === 1">
                                <label for="deformity_right" class="form-text-label kx-label-secondary">
                                    Deformity Right</label>
                                <select class="form-select" aria-label="deformity_right" v-model="deformity">
                                    <option v-for="option in deformity_right_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="mri_remark" class="form-text-label kx-label-secondary">Xray
                                        Remark</label>
                                    <textarea class="form-control" id="xray_diagnosis_remarks"
                                        v-model="xray_diagnosis_remarks" rows="3" placeholder="Remarks"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="hip_complain == '1'">
                    <label for="xray_diagnosis_left" class="form-text-label kx-label-primary">
                        <strong>Hip X-ray Diagnosis</strong>
                    </label>
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="hip_xray_diagnosis_left" class="form-text-label kx-label-secondary">Diagnosis
                                Left</label>
                            <select class="form-select" aria-label="hip_xray_diagnosis_left"
                                v-model="hip_xray_diagnosis_left">
                                <option v-for="option in hip_xray_diagnosis_left_options" :value="option">{{ option
                                    }}</option>
                            </select>
                        </div>
                        <div class="col-sm-6">
                            <label for="hip_xray_diagnosis_right" class="form-text-label kx-label-secondary">Diagnosis
                                Right</label>
                            <select class="form-select" aria-label="hip_xray_diagnosis_right"
                                v-model="hip_xray_diagnosis_right">
                                <option v-for="option in hip_xray_diagnosis_right_options" :value="option">{{ option
                                    }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="mt-5">
                        <label for="xray_diagnosis_left" class="form-text-label kx-label-primary">
                            <strong>MRI Diagnosis </strong>
                        </label>

                        <div class="row">
                            <div class="col-sm-6">
                                <label for="xray_diagnosis_left" class="form-text-label kx-label-secondary">Diagnosis
                                    Left
                                </label>
                                <select class="form-select" aria-label="xray_diagnosis_left"
                                    v-model="mri_diagnosis_left">
                                    <option v-for="option in mri_diagnosis_left_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                                <label for="xray_diagnosis_right" class="form-text-label kx-label-secondary">
                                    Diagnosis Right
                                </label>
                                <select class="form-select" aria-label="xray_diagnosis_right"
                                    v-model="mri_diagnosis_right">
                                    <option v-for="option in mri_diagnosis_right_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="deformity_left" class="form-text-label kx-label-secondary">
                                    Deformity Left</label>
                                <select class="form-select" aria-label="xray_diagnosis_right"
                                    v-model="mri_deformity_left">
                                    <option v-for="option in mri_deformity_left_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>

                                <label for="deformity_right" class="form-text-label kx-label-secondary">
                                    Deformity Right</label>
                                <select class="form-select" aria-label="deformity_right" v-model="mri_deformity_right">
                                    <option v-for="option in mri_deformity_right_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="mri_remark" class="form-text-label kx-label-secondary">MRI
                                        Remark</label>
                                    <textarea class="form-control" id="mri_remark" v-model="mri_assessment_remarks"
                                        rows="3" placeholder="Remarks"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <!-- <div class="row card" style="padding: 15px;">
                <div class="col-12">
                                <label for="prediction_score"
                                        class="form-text-label kx-label-secondary">Prediction Score
                                    </label>
                                <input type="text" class="form-control form-control-sm"
                                    v-model="svf_pridict" placeholder=""
                                    aria-label="svf_pridict" aria-describedby="basic-addon2" style="padding:8px;" readonly/>
                            </div>
                            <div class="col-12">
                                <label for="deformity_correction"
                                        class="form-text-label kx-label-secondary">SVF + Deformity Correction
                                    </label>
                                <input type="text" class="form-control form-control-sm"
                                    v-model="svf_deformity" placeholder=""
                                    aria-label="deformity_correction" aria-describedby="basic-addon2" style="padding:8px;" readonly/>
                            </div>
                        </div> -->
                <table class="table table-bordered text-center mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Surgery</th>
                            <th scope="col">Right</th>
                            <th scope="col">Left</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">SVF</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_svf_right" true-value="1" false-value="0"
                                        id="xray_advise_svf_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_svf_left" true-value="1" false-value="0"
                                        id="xray_advise_svf_left" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">PFO</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_pfo_right" true-value="1" false-value="0"
                                        id="xray_advise_pfo_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_pfo_left" true-value="1" false-value="0"
                                        id="xray_advise_pfo_left" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">HTO</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_hto_right" true-value="1" false-value="0"
                                        id="xray_advise_hto_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_hto_left" true-value="1" false-value="0"
                                        id="xray_advise_hto_left" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">DFO</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_dfo_right" true-value="1" false-value="0"
                                        id="xray_advise_dfo_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_dfo_left" true-value="1" false-value="0"
                                        id="xray_advise_dfo_left" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Arthroscopy</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_arthoscopy_right" true-value="1" false-value="0"
                                        id="xray_advise_arthoscopy_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_arthroscopy_left" true-value="1" false-value="0"
                                        id="xray_advise_arthroscopy_left" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">TKR</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_tkr_right" true-value="1" false-value="0"
                                        id="xray_advise_tkr_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_tkr_left" true-value="1" false-value="0"
                                        id="xray_advise_tkr_left" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">THR</th>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_thr_right" true-value="1" false-value="0"
                                        id="xray_advise_thr_right" />
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-check-input" type="checkbox" value=""
                                        v-model="xray_advise_thr_left" true-value="1" false-value="0"
                                        id="xray_advise_thr_left" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4">
                <button class="btn btn-primary" @click="saveChanges" style="background-color: #007bff;"
                    @mouseover="changeColor" @mouseleave="resetColor">Save</button>
            </div>
        </div>
    </div>

    <!-- doctor assessment popup form data -->
    <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <h2 style="font-weight: bold;">{{ modalTitle }}</h2>
            <div class="assessment_data row">
                <div class="col-sm-8">
                    <div class="row">
                        <div>
                            <label for="xray_diagnosis_left" class="form-text-label kx-label-primary"><strong> Xray
                                    Diagnosis </strong></label>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label for="xray_diagnosis_left" class="form-text-label kx-label-secondary">
                                        Xray Diagnosis Left
                                    </label>
                                    <select class="form-select" aria-label="xray_diagnosis_left"
                                        v-model="xray_diagnosis_left1" disabled>
                                        <option v-for="option in xray_diagnosis_left_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="xray_diagnosis_right" class="form-text-label kx-label-secondary">
                                        Xray Diagnosis Right
                                    </label>
                                    <select class="form-select" aria-label="xray_diagnosis_right"
                                        v-model="xray_diagnosis_right1" disabled>
                                        <option v-for="option in xray_diagnosis_right_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="deformity_left" class="form-text-label kx-label-secondary">
                                        Deformity Left</label>
                                    <select class="form-select" aria-label="xray_diagnosis_right"
                                        v-model="deformity_left1" disabled>
                                        <option v-for="option in deformity_left_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="deformity_right" class="form-text-label kx-label-secondary">
                                        Deformity Right</label>
                                    <select class="form-select" aria-label="deformity_right" v-model="deformity1"
                                        disabled>
                                        <option v-for="option in deformity_right_options" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="mri_remark" class="form-text-label kx-label-secondary">Xray
                                            Remark</label>
                                        <textarea class="form-control" id="xray_diagnosis_remarks" rows="3"
                                            placeholder="Remarks" v-model="xray_diagnosis_remarks1" disabled></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label for="xray_diagnosis_left" class="form-text-label kx-label-primary">
                            <strong>Hip X-ray Diagnosis</strong>
                        </label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label for="hip_xray_diagnosis_left"
                                    class="form-text-label kx-label-secondary">Diagnosis
                                    Left</label>
                                <select class="form-select" aria-label="hip_xray_diagnosis_left"
                                    v-model="hip_xray_diagnosis_left1" disabled>
                                    <option v-for="option in hip_xray_diagnosis_left_options" :value="option">{{ option
                                        }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="hip_xray_diagnosis_right"
                                    class="form-text-label kx-label-secondary">Diagnosis
                                    Right</label>
                                <select class="form-select" aria-label="hip_xray_diagnosis_right"
                                    v-model="hip_xray_diagnosis_right1" disabled>
                                    <option v-for="option in hip_xray_diagnosis_right_options" :value="option">{{ option
                                        }}</option>
                                </select>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="hip_remark" class="form-text-label kx-label-secondary">Hip
                                        Remark</label>
                                    <textarea class="form-control" id="hip_remark" rows="3" placeholder="Remarks"
                                        v-model="hip_remark1" disabled></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label for="mri_diagnosis_left" class="form-text-label kx-label-primary">
                            <strong>MRI Diagnosis </strong>
                        </label>

                        <div class="row">
                            <div class="col-sm-6">
                                <label for="mri_diagnosis_left" class="form-text-label kx-label-secondary">Diagnosis
                                    Left
                                </label>
                                <select class="form-select" aria-label="mri_diagnosis_left"
                                    v-model="mri_diagnosis_left1" disabled>
                                    <option v-for="option in mri_diagnosis_left_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                                <label for="mri_diagnosis_right" class="form-text-label kx-label-secondary">
                                    Diagnosis Right
                                </label>
                                <select class="form-select" aria-label="mri_diagnosis_right"
                                    v-model="mri_diagnosis_right1" disabled>
                                    <option v-for="option in mri_diagnosis_right_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="deformity_left" class="form-text-label kx-label-secondary">
                                    Deformity Left</label>
                                <select class="form-select" aria-label="deformity_right" v-model="mri_deformity_left1"
                                    disabled>
                                    <option v-for="option in mri_deformity_left_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>

                                <label for="deformity_right" class="form-text-label kx-label-secondary">
                                    Deformity Right</label>
                                <select class="form-select" aria-label="deformity_right" v-model="mri_deformity_right1"
                                    disabled>
                                    <option v-for="option in mri_deformity_right_options" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="mri_assessment_remarks" class="form-text-label kx-label-secondary">MRI
                                        Remark</label>
                                    <textarea class="form-control" id="mri_assessment_remarks" rows="3"
                                        placeholder="Remarks" v-model="mri_assessment_remarks1" disabled></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th scope="col">Surgery</th>
                                <th scope="col">Right</th>
                                <th scope="col">Left</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">SVF</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_svf_right" v-model="advise_svf_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_svf_left" v-model="advise_svf_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">PFO</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_pfo_right" v-model="advise_pfo_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_pfo_left" v-model="advise_pfo_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">HTO</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_hto_right" v-model="advise_hto_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_hto_left" v-model="advise_hto_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">DFO</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_dfo_right" v-model="advise_dfo_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_dfo_left" v-model="advise_dfo_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Arthroscopy</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_arthroscopy_right"
                                            v-model="advise_arthoscopy_right1" disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_arthroscopy_left"
                                            v-model="advise_arthroscopy_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">TKR</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_tkr_right" v-model="advise_tkr_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_tkr_left" v-model="advise_tkr_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">THR</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_thr_right" v-model="advise_thr_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_thr_left" v-model="advise_thr_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">PRP</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_prp_right" v-model="advise_prp_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="advise_prp_left" v-model="advise_prp_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Investigations</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="investigations_right" v-model="investigations_right1"
                                            disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="investigations_left" v-model="investigations_left1"
                                            disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Other</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="other_right" v-model="other_right1" disabled />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" true-value="1"
                                            false-value="0" id="other_left" v-model="other_left1" disabled />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="advise_remark" class="form-text-label kx-label-secondary">Advise
                                Remark</label>
                            <textarea class="form-control" id="advise_remark" rows="5" placeholder="Remarks"
                                v-model="advise_remarks1" disabled></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- success messsage  -->
    <div v-if="showModal2" class="modal2" @click.self="closeModal2">
        <div class="modal-content2" style="color: #2E8B57;">
            <span class="close" @click="closeModal2">&times;</span>
            <h3 style="font-weight: bold;">Changes saved successfully!</h3>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "AssessmentTab",
    props: {
        msg: String,
    },
    mounted() {
        this.get_assessment();
        this.get_deformities();
        this.get_mri_deformity();
        this.get_mri_diagnosis();
        this.get_hip_diagnosis();
        this.get_knee_xray_diagnosis();
        this.get_xray_data();
    },
    data() {
        return {
            count: 0,
            assessmentList: [],
            showModal: false,
            showModal2: false,
            modalTitle: "",
            selectedAssessmentIndex: null,

            // Fields to display data
            xray_diagnosis_left1: "",
            xray_diagnosis_right1: "",
            deformity1: "",
            deformity_left1: "",
            hip_xray_diagnosis_left1: "",
            hip_xray_diagnosis_right1: "",
            mri_assessment_remarks1: "",
            mri_deformity_left1: "",
            mri_deformity_right1: "",
            mri_diagnosis_left1: "",
            mri_diagnosis_right1: "",
            name1: "",
            pain_since1: "",
            advise_arthoscopy_right1: "",
            advise_arthroscopy_left1: "",
            advise_dfo_left1: "",
            advise_dfo_right1: "",
            advise_hto_left1: "",
            advise_hto_right1: "",
            advise_pfo_left1: "",
            advise_pfo_right1: "",
            advise_svf_left1: "",
            advise_svf_right1: "",
            advise_thr_left1: "",
            advise_thr_right1: "",
            advise_tkr_left1: "",
            advise_tkr_right1: "",
            advise_prp_left1: "",
            advise_prp_right1: "",
            advise_remarks1: "",
            investigations_right1: "",
            investigations_left1: "",
            other_left1: "",
            other_right1: "",
            diagnosis_left1: "",
            xray_diagnosis_remarks1: "",

            // Options for select fields
            deformity_left_options: [],
            deformity_right_options: [],
            mri_deformity_left_options: [],
            mri_deformity_right_options: [],
            mri_diagnosis_left_options: [],
            hip_xray_diagnosis_left_options: [],
            hip_xray_diagnosis_right_options: [],
            mri_diagnosis_right_options: [],
            xray_diagnosis_left_options: [],
            xray_diagnosis_right_options: [],



            //user data
            deformity: "",
            deformity_1:0,
            svf_pridict:"",
            svf_deformity:"",
            degree_of_deformity: "",
            deformity_left: "",
            hip_xray_diagnosis_left: "",
            hip_xray_diagnosis_right: "",
            hip_remark1: "",
            knee_xray_grade_left: "",
            knee_xray_grade_right: "",
            mri_assessment_remarks: "",
            mri_deformity_left: "",
            mri_deformity_right: "",
            mri_diagnosis_left: "",
            mri_diagnosis_right: "",
            name: "",
            pain_since: "",
            xray_advise_arthoscopy_right: "",
            xray_advise_arthroscopy_left: "",
            xray_advise_dfo_left: "",
            xray_advise_dfo_right: "",
            xray_advise_hto_left: "",
            xray_advise_hto_right: "",
            xray_advise_pfo_left: "",
            xray_advise_pfo_right: "",
            xray_advise_svf_left: "",
            xray_advise_svf_right: "",
            xray_advise_thr_left: "",
            xray_advise_thr_right: "",
            xray_advise_tkr_left: "",
            xray_advise_tkr_right: "",
            xray_diagnosis_left: "",
            xray_diagnosis_remarks: "",
            xray_diagnosis_right: "",
            deformity_left_options: [],
            deformity_right_options: [],
            mri_deformity_left_options: [],
            mri_deformity_right_options: [],
            mri_diagnosis_left_options: [],
            hip_xray_diagnosis_left_options: [],
            hip_xray_diagnosis_right_options: [],
            mri_diagnosis_right_options: [],
            xray_diagnosis_left_options: [],
            xray_diagnosis_right_options: [],
        };
    },

    methods: {

        validateNumericInput(event) {
    // Replace any non-numeric characters with an empty string
    const value = event.target.value;
    this.degree_of_deformity = value.replace(/[^0-9]/g, '');
  },
        get_knee_xray_diagnosis: async function () {
            await axios
                .get("knee_lead.api.knee_xray_diagnosis")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.xray_diagnosis_left_options.push(response.data.message[x]);
                        this.xray_diagnosis_right_options.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },
        get_deformities: async function () {
            await axios
                .get("knee_lead.api.get_deformity")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.deformity_left_options.push(response.data.message[x]);
                        this.deformity_right_options.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },

        get_mri_diagnosis: async function () {
            await axios
                .get("knee_lead.api.get_mri_diagnosis")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.mri_diagnosis_left_options.push(response.data.message[x]);
                        this.mri_diagnosis_right_options.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },
        get_hip_diagnosis: async function () {
            await axios
                .get("knee_lead.api.hip_xray_diagnosis")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.hip_xray_diagnosis_left_options.push(response.data.message[x]);
                        this.hip_xray_diagnosis_right_options.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },
        get_mri_deformity: async function () {
            await axios
                .get("knee_lead.api.get_mri_deformity")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.mri_deformity_left_options.push(response.data.message[x]);
                        this.mri_deformity_right_options.push(response.data.message[x]);
                    }
                })
                .catch((error) => { });
        },
        get_assessment: async function () {
            await axios
                .get("knee_lead.api.get_assessment_by_knee_lead", {
                    params: {
                        mobile: this.$route.params.id,
                    },
                })
                .then((response) => {
                    this.deformity = response.data.data[0].deformity;
                    this.deformity_left = response.data.data[0].deformity_left;
                    this.deformity_1 = response.data.data[0].deformity_1;
                    this.degree_of_deformity = response.data.data[0].degree_of_deformity;
                    this.svf_deformity = response.data.data[0].svf_deformity;
                    this.svf_pridict = response.data.data[0].svf_pridict;
                    this.hip_xray_diagnosis_left =
                        response.data.data[0].hip_xray_diagnosis_left;
                    this.hip_xray_diagnosis_right =
                        response.data.data[0].hip_xray_diagnosis_right;
                    this.knee_xray_grade_left =
                        response.data.data[0].knee_xray_grade_left;
                    this.knee_xray_grade_right =
                        response.data.data[0].knee_xray_grade_right;
                    this.mri_assessment_remarks =
                        response.data.data[0].mri_assessment_remarks;
                    this.hip_complain = response.data.data[0].hip_complain;
                    this.knee_complain = response.data.data[0].knee_complain;
                    this.mri_deformity_left = response.data.data[0].mri_deformity_left;
                    this.mri_deformity_right = response.data.data[0].mri_deformity_right;
                    this.mri_diagnosis_left = response.data.data[0].mri_diagnosis_left;
                    this.mri_diagnosis_right = response.data.data[0].mri_diagnosis_right;
                    this.name = response.data.data[0].name;
                    this.pain_since = response.data.data[0].pain_since;
                    this.xray_advise_arthoscopy_right =
                        response.data.data[0].xray_advise_arthoscopy_right;
                    this.xray_advise_arthroscopy_left =
                        response.data.data[0].xray_advise_arthroscopy_left;
                    this.xray_advise_dfo_left =
                        response.data.data[0].xray_advise_dfo_left;
                    this.xray_advise_dfo_right =
                        response.data.data[0].xray_advise_dfo_right;
                    this.xray_advise_hto_left =
                        response.data.data[0].xray_advise_hto_left;
                    this.xray_advise_hto_right =
                        response.data.data[0].xray_advise_hto_right;
                    this.xray_advise_pfo_left =
                        response.data.data[0].xray_advise_pfo_left;
                    this.xray_advise_pfo_right =
                        response.data.data[0].xray_advise_pfo_right;
                    this.xray_advise_svf_left =
                        response.data.data[0].xray_advise_svf_left;
                    this.xray_advise_svf_right =
                        response.data.data[0].xray_advise_svf_right;
                    this.xray_advise_thr_left =
                        response.data.data[0].xray_advise_thr_left;
                    this.xray_advise_thr_right =
                        response.data.data[0].xray_advise_thr_right;
                    this.xray_advise_tkr_left =
                        response.data.data[0].xray_advise_tkr_left;
                    this.xray_advise_tkr_right =
                        response.data.data[0].xray_advise_tkr_right;
                    this.xray_diagnosis_left = response.data.data[0].xray_diagnosis_left;
                    this.xray_diagnosis_remarks =
                        response.data.data[0].xray_diagnosis_remarks;
                    this.xray_diagnosis_right =
                        response.data.data[0].xray_diagnosis_right;
                })
                .catch((error) => { });
        },

        // update fields data function
        saveChanges: async function () {
            try {
                let updatedFields = {
                    name: this.$route.params.id,
                    xray_diagnosis_left: this.xray_diagnosis_left,
                    xray_diagnosis_right: this.xray_diagnosis_right,
                    deformity_left: this.deformity_left,
                    deformity_1: Number(this.deformity_1),
                    degree_of_deformity: this.degree_of_deformity,
                    deformity: this.deformity,
                    xray_diagnosis_remarks: this.xray_diagnosis_remarks,
                    hip_xray_diagnosis_left: this.hip_xray_diagnosis_left,
                    hip_xray_diagnosis_right: this.hip_xray_diagnosis_right,
                    hip_remarks: this.hip_remarks,
                    mri_diagnosis_left: this.mri_diagnosis_left,
                    mri_diagnosis_right: this.mri_diagnosis_right,
                    mri_deformity_left: this.mri_deformity_left,
                    mri_deformity_right: this.mri_deformity_right,
                    mri_assessment_remarks: this.mri_assessment_remarks,
                    xray_advise_svf_left: this.xray_advise_svf_left,
                    xray_advise_pfo_left: this.xray_advise_pfo_left,
                    xray_advise_hto_left: this.xray_advise_hto_left,
                    xray_advise_dfo_left: this.xray_advise_dfo_left,
                    xray_advise_arthroscopy_left: this.xray_advise_arthroscopy_left,
                    xray_advise_tkr_left: this.xray_advise_tkr_left,
                    xray_advise_thr_left: this.xray_advise_thr_left,
                    xray_advise_svf_right: this.xray_advise_svf_right,
                    xray_advise_pfo_right: this.xray_advise_pfo_right,
                    xray_advise_hto_right: this.xray_advise_hto_right,
                    xray_advise_dfo_right: this.xray_advise_dfo_right,
                    xray_advise_arthoscopy_right: this.xray_advise_arthoscopy_right,
                    xray_advise_tkr_right: this.xray_advise_tkr_right,
                    xray_advise_thr_right: this.xray_advise_thr_right,

                };
                await this.update_fields(updatedFields);
                this.showModal2 = true;
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            } catch (error) {
                console.error('Error saving changes:', error);
            }
        },

        closeModal2() {
            this.showModal2 = false;
        },

        update_fields: async function (updatedFields) {
            try {
                const response = await axios.post("knee_lead.knee_lead_details.update_knee_lead_assessment_tab", updatedFields);
            } catch (error) {
                console.error('Error updating fields:', error);
                throw error;
            }
        },

        get_xray_data: async function () {
            await axios
                .get("knee_lead.xray_assessment.get_knee_lead_xray_assessment", {
                    params: {
                        knee_lead: this.$route.params.id,
                    },
                })
                .then((response) => {
                    this.count = response.data.data.count;
                    this.assessmentList = response.data.data.assessment_list;
                })
                .catch((error) => { });
        },


        //assessment details for doctor
        getAssessmentDetails(assessmentId) {
            axios
                .post("knee_lead.xray_assessment.get_xray_assessment_details", {
                    name: assessmentId
                })
                .then(response => {
                    const assessmentDetails = response.data.data[0];
                    this.xray_diagnosis_left1 = assessmentDetails.xray_diagnosis_left;
                    this.xray_diagnosis_right1 = assessmentDetails.xray_diagnosis_right;
                    this.xray_diagnosis_remarks1 = assessmentDetails.xray_diagnosis_remarks;
                    this.deformity1 = assessmentDetails.deformity;
                    this.deformity_left1 = assessmentDetails.deformity_left;
                    this.hip_xray_diagnosis_left1 = assessmentDetails.hip_xray_diagnosis_left;
                    this.hip_xray_diagnosis_right1 = assessmentDetails.hip_xray_diagnosis_right;
                    this.hip_remark1 = assessmentDetails.hip_remarks;
                    this.mri_assessment_remarks1 = assessmentDetails.mri_assessment_remarks;
                    this.mri_deformity_left1 = assessmentDetails.mri_deformity_left;
                    this.mri_deformity_right1 = assessmentDetails.mri_deformity_right;
                    this.mri_diagnosis_left1 = assessmentDetails.mri_diagnosis_left;
                    this.mri_diagnosis_right1 = assessmentDetails.mri_diagnosis_right;
                    this.advise_arthoscopy_right1 = assessmentDetails.advise_arthroscopy_right;
                    this.advise_arthroscopy_left1 = assessmentDetails.advise_arthroscopy_left;
                    this.advise_dfo_left1 = assessmentDetails.advise_dfo_left;
                    this.advise_dfo_right1 = assessmentDetails.advise_dfo_right;
                    this.advise_hto_left1 = assessmentDetails.advise_hto_left;
                    this.advise_hto_right1 = assessmentDetails.advise_hto_right;
                    this.advise_pfo_left1 = assessmentDetails.advise_pfo_left;
                    this.advise_pfo_right1 = assessmentDetails.advise_pfo_right;
                    this.advise_svf_left1 = assessmentDetails.advise_svf_left;
                    this.advise_svf_right1 = assessmentDetails.advise_svf_right;
                    this.advise_thr_left1 = assessmentDetails.advise_thr_left;
                    this.advise_thr_right1 = assessmentDetails.advise_thr_right;
                    this.advise_tkr_left1 = assessmentDetails.advise_tkr_left;
                    this.advise_tkr_right1 = assessmentDetails.advise_tkr_right;
                    this.advise_prp_left1 = assessmentDetails.advise_prp_left;
                    this.advise_prp_right1 = assessmentDetails.advise_prp_right;
                    this.advise_remarks1 = assessmentDetails.advise_remark;
                    this.investigations_right1 = assessmentDetails.investigations_right;
                    this.investigations_left1 = assessmentDetails.investigations_left;
                    this.other_left1 = assessmentDetails.other_left;
                    this.other_right1 = assessmentDetails.other_right;

                })
                .catch(error => {
                    console.error("Error fetching assessment details:", error);
                });
        },

        formatDate(dateTime) {
            //   if (!dateTime) {
            //     return "Not Creation";
            //   }
            const date = new Date(dateTime);
            const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true, // Use 12-hour format
            };

            return date.toLocaleDateString("en-GB", options);
        },
        hoverColor(enter, index) {
            if (enter) {
                this.$set(this.assessmentList, index, { ...this.assessmentList[index], hover: true });
            } else {
                this.$set(this.assessmentList, index, { ...this.assessmentList[index], hover: false });
            }
        },

        openModal(assessment) {
            // Set the selected assessment and modal title
            this.selectedAssessment = assessment;
            this.modalTitle = `Assessment ${this.assessmentList.indexOf(assessment) + 1}`;

            // Pass the assessment ID to the method to fetch data
            this.getAssessmentDetails(assessment.name);
            this.showModal = true;
        },

        closeModal() {
            // Reset values and close the modal
            this.modalTitle = "";
            this.showModal = false;
        },

        changeColor(event) {
            event.target.style.backgroundColor = '#0056b3';
        },
        resetColor(event) {
            event.target.style.backgroundColor = '#007bff';
        }
    },
};
</script>

<style scoped>
.modal {
    /* Styles for modal overlay */
    position: fixed;
    top: 0;
    padding-top: 500px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    /* Styles for modal content */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    position: relative;
    /* Ensure the close icon stays in the modal */
}

.modal2 {
    /* Styles for modal overlay */
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content2 {
    /* Styles for modal content */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
    /* Ensure the close icon stays in the modal */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}
</style>