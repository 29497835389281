<template>
    <div>
        <div v-if="loading1" class="loader2-container">
      <div class="loader2"></div>
    </div>
    <div v-else class="row">
        <div class="col-sm-8">
            <div class="row">
                <div class="col-sm-4">
                    <label for="patient_full_name" class="form-text-label kx-label-secondary">Patient Name</label>
                    <input type="text" class="form-control" id="patient_full_name" v-model="patient_full_name" />
                </div>
                <div class="col-sm-2">
                    <label for="age" class="form-text-label kx-label-secondary">Age</label>
                    <input type="text" class="form-control" id="age" maxlength="2" v-model="age"
                        @keypress="onlyNumber" />
                </div>
                <div class="col-sm-2">
                    <label for="gender" class="form-text-label kx-label-secondary">Gender</label>
                    <select class="form-select" id="gender" aria-label="gender" v-model="gender">
                        <option v-for="option in gender_options" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label for="occupation" class="form-text-label kx-label-secondary">Occupation</label>
                    <select class="form-select" id="occupation" aria-label="occupation" v-model="occupation">
                        <option v-for="option in patient_occupation_options" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <label class="form-select-label kx-label-secondary" for="HelthScheme">Health Scheme</label>
                    <select class="form-select" aria-label="HelthScheme" v-model="health_scheme">
                        <option v-for="option in health_scheme_options" :value="option">
                            {{ option }}
                        </option>
                    </select>
                    <div class="mt-1">
                        <input type="checkbox" class="form-check-input" id="mediclaim" v-model="mediclaim"
                            true-value="1" false-value="0" />
                        <label for="mediclaim" class="form-text-label small">
                            &nbsp; Mediclaim</label>
                    </div>                    
                </div>
                <div class="col-sm-3">
                    <label class="form-select-label kx-label-secondary" for="City">City <strong style="color: red;">*</strong></label>

                    <select class="form-select" aria-label="City" v-model="city">
                        <option v-for="option in city_options" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label class="form-select-label kx-label-secondary" for="State">State <strong style="color: red;">*</strong></label>
                    <select class="form-select" aria-label="State" v-model="patient_state">
                        <option v-for="option in patient_state_options" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <label for="patient_full_name" class="form-text-label kx-label-secondary">Mobile</label>
                    <input type="text" class="form-control" id="mobile" v-model="mobile" readonly
                        style="background-color: #efeded;" />
                </div>
                <div class="col-sm-4">
                    <label for="whatsapp_no" class="form-text-label kx-label-secondary">WhatsApp No.</label>
                    <input type="text" class="form-control" id="whatsapp_no" v-model="whatsapp_no" />

                    <!-- Checkbox and label for "Same As Mobile" -->
                    <div class="mt-1">
                        <input type="checkbox" class="form-check-input" id="same_whatsapp_no" v-model="same_whatsapp_no"
                            true-value="1" false-value="0" />
                        <label for="same_whatsapp_no" class="form-text-label small">
                            &nbsp; Same As Mobile</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label for="patient_full_name" class="form-text-label kx-label-secondary">Lead
                            Source</label>
                        <input type="text" class="form-control" id="record_type" v-model="record_type" readonly
                            style="background-color: #efeded;" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div>
                <label class="form-select-label kx-label-secondary" for="State">Status</label>
                <select class="form-select" aria-label="State" v-model="knee_lead_status">
                    <option v-for="option in lead_status_options" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
            <div>
                <label class="form-select-label kx-label-secondary" for="State">Tags</label>
                <Multiselect v-model="tags" mode="tags" placeholder="Select your characters" :datasource="tag_options"
                    :fields="tag_options_fields" :options="tag_options" :searchable="true" :createTag="true"
                    @select="save_tags" :on-tag="addTag" :allow-empty="false" @remove="remove_tags">
                </Multiselect>
            </div>
            <div>
                <label class="form-select-label kx-label-secondary" for="State">Language <strong style="color: red;">*</strong></label>
                <select class="form-select" aria-label="State" v-model="language">
                    <option v-for="option in language_options" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
            <div>
                <label class="form-select-label kx-label-secondary" for="State">Camp</label>

                <select class="form-select" aria-label="State" v-model="camp">
                    <option v-for="option in camp_options" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
            <div class="row">
                <div class="col mt-4">
                    <button class="btn btn-sm btn-success kx-button" style="color: white; margin-right: 2px"
                        @click="click2call($route.params.id)">
                        <i class="fa fa-phone icon-rotate" aria-hidden="true"></i>
                        Call
                    </button>
                    <button class="btn btn-sm btn-info kx-button" style="color: white; margin-right: 2px"
                        @click="fnShowShareModel($route.params.id)">
                        <i class="fas fa-share-alt icon-rotate"></i>
                        Share
                    </button>
                    <button class="btn btn-sm btn-info kx-button" style="color: white; margin-right: 2px"
                        @click="fnShowShareWaModel(name)">
                        <i class="fas fa-share icon-rotate"></i>
                        Send WhatsApp
                    </button>
                   
                    <button v-if="['OPD visited', 'PRP Done', 'Video Consultation'].includes(knee_lead_status)" class="btn btn-sm btn-info kx-button" style="color: white; margin-right: 2px"
                        @click="ClaimModel(name)">
                        <i class="fas fa-share icon-rotate"></i>
                       Claim
                    </button>
                </div>
            </div>
        </div>
        <div class=" col-sm-3 mt-1 mb-4">
            <button class="btn btn-primary" @click="saveChanges" style="background-color: #007bff;"
                @mouseover="changeColor" @mouseleave="resetColor">Save</button>
        </div>
    </div>
</div>
<OpdModel @add="ShowClaimModel = true" 
          @close="ShowClaimModel = false" 
          :show="ShowClaimModel" 
          :Knee_lead="whatsapp_no" 
/>
<VideoModel @add="ShowVideoModel = true" @close="ShowVideoModel = false" :show="ShowVideoModel" :Knee_lead="whatsapp_no"/>
<PrpModel @add="ShowPrpModel = true" @close="ShowPrpModel = false" :show="ShowPrpModel" :Knee_lead="whatsapp_no"/>
    <ModalWaShare @add="ShowShareWaModel = true" @close="ShowShareWaModel = false" :show="ShowShareWaModel"
        :Knee_lead="whatsapp_no" msg="TIkam" />
    <ModalShare @add="ShowShareModel = true" @close="ShowShareModel = false" :show="ShowShareModel"
        :Knee_lead="Knee_lead_id" msg="TIkam" />
    <ChatBot :mobile="$route.params.id" />
</template>

<script>
import axios from "axios";
import {ref} from "vue";
import SideBaar from "@/components/SideBaar.vue";
import Multiselect from "@vueform/multiselect";
import Header from "@/components/Header/Header.vue";
import BasicDetails from "@/components/Tabs/BasicDetails.vue";
import ModalWaShare from "@/components/modals/ShareWaModel.vue";
import ModalShare from "@/components/modals/ShareModel.vue";
import ChatBot from "@/components/modals/ChatBot.vue";
import VueAwesomePaginate from "vue-awesome-paginate";
import {
    FloatMenu
} from "vue-float-menu";
import OpdModel from "../modals/OpdModel.vue";
import VideoModel from "../modals/VideoModel.vue";
import PrpModel from "../modals/PrpModel.vue";
export default {
    name: "EditBasicDetails",
    components: {
        SideBaar,
        Multiselect,
        Header,
        SideBaar,
        VueAwesomePaginate,
        FloatMenu,
        Multiselect,
        BasicDetails,
        ModalWaShare,
        ModalShare,
        ChatBot,
        OpdModel,
        VideoModel,
        PrpModel
    },
    props: {
        msg: String,
    },
    mounted() {
        this.get_data_from_lead()
        this.get_health_scheme();
        this.get_city_state();
        this.get_state();
        this.get_occupation();
        this.get_knee_lead_status();
        this.get_tags();
        this.get_language_list();
        this.get_camp_list();
    },
    data() {
        return {
            loading: true,
            loading1: true,
            name: "",
            patient_full_name: "",
            gender: "",
            age: "",
            occupation: "",
            is_a_healthcare_practitioner: "",
            mobile: "",
            verified_m: "",
            whatsapp_no: "",
            verified_wa: "",
            city: "",
            state: "",
            pincode: "",
            mediclaim: 0,
            mediclaim_no: "",
            tpa: "",
            health_scheme: "",
            profile_verified: "",
            patient_state: "",
            disabled: false,
            health_scheme_disabled: false,
            health_scheme_options: [],
            city_options: [],
            patient_state_options: [],
            patient_occupation_options: [],
            gender_options: ["Male", "Female", "Other"],
            lead_status_options: [],
            knee_lead_status: "",
            tag_options: [],
            tags: "",
            tags: [],
            language_options: [],
            language: "",
            camp: "",
            camp_options: [],
            same_whatsapp_no: 0,
            ShowShareWaModel: ref(false),
            ShowVideoModel: ref(false),
            ShowPrpModel: ref(false),
            ShowClaimModel: ref(false),
            ShowShareModel: ref(false),
        };
    },
    methods: {

        click2call: function (e) {
            (this.loading = !false),
                axios
                    .get("knee_lead.api.click2call?number=" + e)
                    .then((response) => {
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.errmessage = error;
                        this.ShowErrModel = true;
                    });
        },

        fnShowShareModel: function (e) {
            this.Knee_lead_id = e;
            this.ShowShareModel = !this.ShowShareModel;
        },

        ClaimModel: function (e) {
        this.Knee_lead_id = e;
        if (this.knee_lead_status === "OPD visited") {
            this.ShowClaimModel = true;
        } else if (this.knee_lead_status === "Video Consultation") {
            this.ShowVideoModel = true;
        } else if (this.knee_lead_status === "PRP Done") {
            this.ShowPrpModel = true;
        } else {
            // Handle other statuses if needed
            
        }
    },

        fnShowShareWaModel: function (e) {
            this.Knee_lead_id = e;
            this.ShowShareWaModel = !this.ShowShareWaModel;
        },

        // language fields data
        get_language_list: async function () {
            await axios
                .get("knee_lead.api.get_language")
                .then((response) => {
                    var res_data = response.data.data;
                    for (let x in res_data) {
                        this.language_options.push(res_data[x].name);
                    }
                })
                .catch((error) => { });
        },

        remove_tags: function (tag) {
            const formdata = {
                knee_lead: this.$route.params.id,
                tag: tag,
            };
            axios
                .put("knee_lead.api.remove_tag", formdata)
                .then((response) => {
                    // Handle success
                })
                .catch((error) => {
                    // Handle error
                });
        },

        addTag: function () { },

        save_tags: function () {
            
            for (let x in this.tags) {
                const formdata = {
                    knee_lead: this.$route.params.id,
                    tag: this.tags[x],
                };
                axios
                    .put("knee_lead.api.add_tag", formdata)
                    .then((response) => { })
                    .catch((error) => { });
            }
        },


        // camp field list
        get_camp_list: async function () {
            await axios
                .get("knee_lead.api.get_camp_list")
                .then((response) => {
                    var res_data = response.data.data[0];
                    for (let x in res_data) {
                        this.camp_options.push(res_data[x].name);
                    }
                })
                .catch((error) => { });
        },

        // tag field list 
        get_tags: function () {
            axios
                .get("knee_lead.api.get_tag")
                .then((response) => {
                    this.loading = false;
                    this.tag_options = response.data.data.map((tag) => tag.name);
                })
                .catch((error) => {
                    window.location.reload();
                });
        },

        // knee lead status
        get_knee_lead_status: async function () {
            await axios
                .get("knee_lead.api.knee_lead_status")
                .then((response) => {
                    for (let x in response.data.message) {
                        this.lead_status_options.push(response.data.message[x].name);
                    }
                })
                .catch((error) => { });
        },

        get_data_from_lead: async function () {
            this.loading = !false;
            await axios
                .get("knee_lead.api.get_single_knee_lead", {
                    params: {
                        mobile: this.$route.params.id,
                    },
                })
                .then((response) => {
                    this.items = response.data.data;
                    this.knee_lead_status = this.items[0].knee_lead_status[0].name;
                    this.record_type = response.data.data[0].record_type;
                    this.patient_full_name = this.items[0].patient_full_name;
                    this.camp = this.items[0].camp;
                    this.language = this.items[0].language;
                    this.opd_location = this.items[0].opd_location;
                    this.mobile = this.items[0].mobile;
                    this.name = this.items[0].name;
                    this.gender = this.items[0].gender;
                    this.tag_data = this.items[0]._user_tags;
                    for (let x in this.items[0]._user_tags) {
                        this.tags.push(this.items[0]._user_tags[x].name);
                    }
                    this.age = this.items[0].age;

                    this.occupation = this.items[0].occupation;
                    this.city = this.items[0].city;
                    this.patient_state = this.items[0].state;

                    this.pain_since = this.items[0].pain_since;
                    if (this.items[0].pain_since) {
                        this.pain_since_disable = true;
                    }
                    this.health_scheme = this.items[0].health_scheme;
                    this.whatsapp_no = this.items[0].whatsapp_no;
                    this.same_whatsapp_no = response.data.data[0].same_whatsapp_no;
                    this.mediclaim = response.data.data[0].mediclaim;
                    this.loading = false;
                    this.loading1 = false;
                    this.validate_complaint();
                })
                
                .catch((error) => { });
        },
        get_health_scheme: async function () {
            await axios
                .get("knee_lead.api.get_health_scheme")
                .then((response) => {
                    for (let x in response.data.data) {
                        this.health_scheme_options.push(response.data.data[x].name);
                    }
                })
                .catch((error) => { });
        },
        onlyNumber: function ($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        get_city_state: async function () {
            await axios
                .get("knee_lead.api.get_city")
                .then((response) => {
                    for (let x in response.data.data) {
                        this.city_options.push(response.data.data[x].city);
                    }
                    this.city_state_options = response.data.data;
                })
                .catch((error) => { });
        },
        get_state: async function () {
            await axios
                .get("knee_lead.api.get_state")
                .then((response) => {
                    for (let x in response.data.data) {
                        this.patient_state_options.push(response.data.data[x].state);
                    }

                    // this.
                })
                .catch((error) => { });
        },
        get_occupation: async function () {
            await axios
                .get("knee_lead.api.get_occupation")
                .then((response) => {
                    for (let x in response.data.data) {
                        this.patient_occupation_options.push(response.data.data[x].name);
                    }
                })
                .catch((error) => { });
        },

        saveChanges: async function () {
            this.loading1 = true;
            if (!["Open", "Open Not Answered","Contacted","Do Not Contact"].includes(this.knee_lead_status)) {
        // Validate if city, status, and language are filled
        if (!this.city || !this.knee_lead_status || !this.language) {
            alert("Please fill in the mandatory fields: City, Status, and Language.");
            this.loading1 = false;
            return;
        }
    }

            try {
                let updatedFields = {
                    name: this.$route.params.id,
                    patient_full_name: this.patient_full_name ? this.patient_full_name : "",
                    age: this.age,
                    gender: this.gender,
                    occupation: this.occupation,
                    health_scheme: this.health_scheme,
                    city: this.city,
                    state: this.patient_state,
                    mobile: this.mobile,
                    mediclaim: parseInt(this.mediclaim),
                    same_whatsapp_no: parseInt(this.same_whatsapp_no),
                    whatsapp_no: this.whatsapp_no,
                    knee_lead_status: this.knee_lead_status,
                    language: this.language,
                    camp: this.camp
                    // Add more fields as needed
                };
                await this.update_fields(updatedFields);
                window.location.reload()
            } catch (error) {
                console.error('Error saving changes:', error);
            }finally {
                // Stop the loader
                this.loading1 = false;
            }
        },

        update_fields: async function (updatedFields) {
            try {
                const response = await axios.post("knee_lead.knee_lead_details.update_knee_lead_basic_details", updatedFields);
                this.get_data_from_lead();
            } catch (error) {
                console.error('Error updating fields:', error);
                throw error;
            }
        },

        changeColor(event) {
            event.target.style.backgroundColor = '#0056b3';
        },
        resetColor(event) {
            event.target.style.backgroundColor = '#007bff';
        }
    },
};
</script>

<style>

.loader2-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader2 {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
