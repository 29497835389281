<template>
  <div id="content">
    <SideBar></SideBar>
    <Header></Header>
    <div class="button-container">
      <button @click="opdBooked" class="button">OPD Booked</button>
      <button @click="prpDone" class="button">PRP</button>
      <button @click="svfData" class="button">SVF</button>
      <button @click="physiotherapy" class="button">Physiotherapy</button>
      <button @click="showAll" class="button">Show All</button>
    </div>
  </div>
  <div class="calendar-heading">

  </div>
  <div v-if="activeTab === 'opdBooked'" class="calendar-container">
    <div class="date-picker" style="text-align: end;">
      <label for="fromDate" style="margin: 5px;font-weight: bold;">From:</label>
      <input type="date" v-model="fromDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px; margin:5px;" />

      <label for="toDate" style="margin: 5px;font-weight: bold;">To:</label>
      <input type="date" v-model="toDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px;margin: 5px;" />
    </div>
    <h3 style="margin-top: -15px;">OPD Booked Calendar</h3>
    <FullCalendar :options="calendarOptions" class="custom-calendar" @eventClick="handleEventClick" />
  </div>
  <div v-if="activeTab === 'prpDone'" class="calendar-container2">
    <div class="date-picker" style="text-align: end;">
      <label for="fromDate" style="margin: 5px;font-weight: bold;">From:</label>
      <input type="date" v-model="fromDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px; margin:5px;" />

      <label for="toDate" style="margin: 5px;font-weight: bold;">To:</label>
      <input type="date" v-model="toDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px;margin: 5px;" />
    </div>
    <h3 style="margin-top: -15px;">PRP Calendar</h3>
    <FullCalendar :options="calendarOptions" class="custom-calendar" @eventClick="handleEventClick" />
  </div>
  <div v-if="activeTab === 'physiotherapy'" class="calendar-container3">
    <div class="date-picker" style="text-align: end;">
      <label for="fromDate" style="margin: 5px;font-weight: bold;">From:</label>
      <input type="date" v-model="fromDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px; margin:5px;" />

      <label for="toDate" style="margin: 5px;font-weight: bold;">To:</label>
      <input type="date" v-model="toDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px;margin: 5px;" />
    </div>
    <h3 style="margin-top: -15px;">Physiotherapy Calendar</h3>
    <FullCalendar :options="calendarOptions" class="custom-calendar" @eventClick="handleEventClick" />
  </div>

  <div v-if="activeTab === 'svfData'" class="calendar-container4">
    <div class="date-picker" style="text-align: end;">
      <label for="fromDate" style="margin: 5px;font-weight: bold;">From:</label>
      <input type="date" v-model="fromDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px; margin:5px;" />

      <label for="toDate" style="margin: 5px;font-weight: bold;">To:</label>
      <input type="date" v-model="toDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px;margin: 5px;" />
    </div>
    <h3 style="margin-top: -15px;">SVF Calendar</h3>
    <FullCalendar :options="calendarOptions" class="custom-calendar" @eventClick="handleEventClick" />
  </div>

  <div v-if="activeTab === 'showAll'" class="calendar-container5">
    <div class="date-picker" style="text-align: end;">
      <label for="fromDate" style="margin: 5px;font-weight: bold;">From:</label>
      <input type="date" v-model="fromDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px; margin:5px;" />

      <label for="toDate" style="margin: 5px;font-weight: bold;">To:</label>
      <input type="date" v-model="toDate" @change="fetchCalendarEvents" :min="minDate" :max="maxDate"
        style="width:200px;height:auto;padding: 5px;border-radius: 5px;margin: 5px;" />
    </div>
    <h3 style="margin-top: -15px;">Show All Calendar</h3>
    <FullCalendar :options="calendarOptions" class="custom-calendar" @eventClick="handleEventClick" />
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "@vueform/multiselect";
import SideBar from "@/components/SideBaar.vue";
import Header from "@/components/Header/Header.vue";
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';

export default {
  components: {
    Header,
    SideBar,
    Multiselect,
    FullCalendar,
  },
  data() {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    return {
      activeTab: 'opdBooked',
      startDate: this.formatDate(startOfWeek),
      endDate: this.formatDate(endOfWeek),
      calendarOptions: {
        plugins: [dayGridPlugin],
        events: [], // Your events will be populated here
        eventContent: this.eventContent,
        eventClick: this.handleEventClick,


      },
    };
  },
  methods: {
    async opdBooked() {
      this.activeTab = 'opdBooked';
      await this.fetchCalendarEvents();
    },
    async prpDone() {
      this.activeTab = 'prpDone';
      await this.fetchCalendarEvents();
    },
    async physiotherapy() {
      this.activeTab = 'physiotherapy';
      await this.fetchCalendarEvents();
    },
    async svfData() {
      this.activeTab = 'svfData';
      await this.fetchCalendarEvents();
    },
    async showAll() {
      this.activeTab = 'showAll';
      await this.fetchCalendarEvents();
    },

    // handleEventClick(info) {

    //   const eventId = info.event.title;
    //   this.$router.push({ name: 'LeadDetails', params: { id: eventId } });
    // },
    handleEventClick(info) {
      const eventId = info.event.title;
      const routeUrl = this.$router.resolve({ name: 'LeadDetails', params: { id: eventId } }).href;
      window.open(routeUrl, '_blank');
    },

    async fetchCalendarEvents() {
      try {
        let apiEndpoint = '';

        if (this.activeTab === 'opdBooked') {
          apiEndpoint = 'knee_lead.calendar.get_opd_calendar_events';
        } else if (this.activeTab === 'prpDone') {
          apiEndpoint = 'knee_lead.calendar.get_prp_calendar_events';
        } else if (this.activeTab === 'physiotherapy') {
          apiEndpoint = 'knee_lead.calendar.get_physiotherapy_calendar_events';
        } else if (this.activeTab === 'svfData') {
          apiEndpoint = 'knee_lead.calendar.get_svf_calendar_events';
        } else if (this.activeTab === 'showAll') {
          apiEndpoint = 'knee_lead.calendar.get_calendar_events';
        }

        const response = await axios.get(apiEndpoint, {
          params: {
            start_date: this.fromDate,
            end_date: this.toDate,
          },
        });

        if (response.status === 200 && response.data.status_code === 200) {
          const events = response.data.data.events.map((event) => ({
            patient_full_name: event.patient_full_name,
            city: event.city,
            title: event.title,
            start: new Date(event.start),
            id: event.id,
            eventType: event.event_type
          }));

          const eventsGrouped = this.groupEventsByDate(events);

          this.calendarOptions.eventSources = Object.values(eventsGrouped);
        } else {
          console.error('Failed to fetch calendar events:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching calendar events:', error);
      }
    },

    formatDate(date) {
      return date.toISOString().split('T')[0];
    },

    eventContent(info) {
      const eventTime = info.event.start.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      let contentHtml = `<center 
    onMouseOver="this.style.color='#282828'; this.title='${info.event.extendedProps.patient_full_name} - ${info.event.extendedProps.city} - ${info.event.title}'"
    style="margin-top: 10px;">`;

      if (this.activeTab === 'showAll') {
        const eventType = info.event.extendedProps.eventType;
        if (eventType === 'Physiotherapy') {
          contentHtml += `<b style="font-size:10px; color: purple;">${eventType}</b><br/>`;
        }
        else if (eventType === 'OPD') {
          contentHtml += `<b style="font-size:10px; color: green;">${eventType}</b><br/>`;
        }
        else if (eventType === 'PRP') {
          contentHtml += `<b style="font-size:10px; color: red;">${eventType}</b><br/>`;
        }
        if (eventType === 'SVF') {
          contentHtml += `<b style="font-size:10px; color: blue;">${eventType}</b><br/>`;
        }
      }
      contentHtml += `
      <b style="font-size:10px;color:#282828;" title="${info.event.extendedProps.city} - ${info.event.title}">${info.event.extendedProps.patient_full_name} -<b style="font-size:10px;"> ${eventTime}</b></b>
    `;
      contentHtml += `</center>`;

      return {
        html: contentHtml,
      };
    },

    groupEventsByDate(events) {
      const eventsGrouped = {};
      events.forEach((event) => {
        const dateKey = this.formatDate(event.start);

        if (!eventsGrouped[dateKey]) {
          eventsGrouped[dateKey] = [];
        }

        eventsGrouped[dateKey].push(event);
      });

      Object.values(eventsGrouped).forEach((eventGroup) => {
        eventGroup.forEach((event, index) => {
          event.number = eventGroup.length - index;
        });
      });

      return eventsGrouped;
    },

  },
  mounted() {
    this.fetchCalendarEvents(); // Call fetchCalendarEvents when the component is mounted
  },
  watch: {
    startDate: 'fetchCalendarEvents',
    endDate: 'fetchCalendarEvents',
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  background-color: #2bafcd;
  color: white;
  padding: 10px 15px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.calendar-container {
  margin: 30px auto;
  max-width: 900px;
  /* Adjust the maximum width as needed */
}

.calendar-container2 {
  margin: 30px auto;
  max-width: 900px;
  /* Adjust the maximum width as needed */
}

.calendar-container3 {
  margin: 30px auto;
  max-width: 900px;
  /* Adjust the maximum width as needed */
}

.calendar-container4 {
  margin: 30px auto;
  max-width: 900px;
  /* Adjust the maximum width as needed */
}

.calendar-container5 {
  margin: 30px auto;
  max-width: 900px;
  /* Adjust the maximum width as needed */
}
</style>
