<template>
  <div class="modal-mask" v-if="show">
    <div class="modal-container rounded">
      <div class="modal-header">
        <h2 class="modal-title fs-5" id="exampleModalLabel" style="font-weight: bold;">
          Claim
        </h2>

        <button type="button" class="btn-close" data-bs-dismiss="modal-mask" aria-label="Close"
          @click="$emit('close')" :disabled="loading"></button>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="Group" style="font-weight: bold;">Date of Video Consultation *</label>
            <VueDatePicker 
              id="date" 
              auto-apply 
              v-model="date" 
              format="dd/MM/yyyy" 
              :disabled="loading" 
              :disabled-dates="disableFutureDates">
            </VueDatePicker>
          </div>
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">Duration of Video Consultation *</label>
            <input class="form-control" type="text" v-model="duration" required :disabled="loading"/>
          </div>
          <div class="col-sm-6 mt-4">
            <label class="form-check-label" for="users" style="font-weight: bold;">Upload ScreenShot *</label>
            <input type="file" class="form-control"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, image/webp"
              @change="handleImageUpload($event)" :disabled="loading" required/>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-5">
        <button class="btn btn-sm btn-primary mt-2" @click="saveData" :disabled="!isFormValid || loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
   <!-- Success Modal -->
   <div class="modal-mask" v-if="showSuccessModal">
    <div class="modal-container rounded">
      <div class="modal-body" style="display: flex;flex-direction: row; justify-content: center;">
        <h2 class="mt-5 mb-5" :style="{ color: messageColor, fontWeight: 'bold', textAlign: 'center' }">
          {{ message }}
        </h2>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-primary" @click="closeSuccessModal">Close</button>
      </div>
    </div>
   </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VideoModel",
  props: {
    msg: String,
    show: false,
    exercise_options: [],
    Knee_lead: "",
  },
  data() {
    return {
      knee_lead: this.$route.params.id,
      duration: "",
      date: null,
      mode: "Online",
      filedata: "",
      filename: "",
      showSuccessModal: false,
      message: "",
      status_code: null,
      loading: false, // new loading state
    };
  },
  computed: {
    isFormValid() {
      return this.mode && this.date && this.duration && this.filedata; // Check if all fields are filled
    },
    messageColor() {
      // Dynamically return the color based on the status code
      return this.status_code === 200 ? 'green' : (this.status_code === 500 ? 'red' : 'black');
    }
  },
  methods: {
    // Convert the call duration to seconds
    convertCallDurationToSeconds() {
      const durationInMinutes = parseFloat(this.duration);
      if (!isNaN(durationInMinutes)) {
        return durationInMinutes * 60; // Convert minutes to seconds
      }
      return 0; // If input is not a number, default to 0 seconds
    },

    disableFutureDates(date) {
  const today = new Date(); // Current date with time
  const selectedDate = new Date(date); // Date from the date picker
  
  // Use only the date part (ignore time) for both today's date and the selected date
  today.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);
  
  // Disable future dates by comparing date only
  return selectedDate > today;
},

    // Handle image upload
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.filedata = e.target.result;
          this.filename = file.name;
        };
        reader.readAsDataURL(file);
      }
    },

    // Save data
    saveData() {
      if (!this.isFormValid) return;
      this.loading = true; // Set loading to true when saving data starts
      const formattedDate = this.date ? new Date(this.date).toISOString().split('T')[0] : null;
      const VideoDurationInSeconds = this.convertCallDurationToSeconds();
      const requestData = {
        duration_of_video_consultation: VideoDurationInSeconds,
        mode_of_video_consultation: this.mode,
        date_of_video_consultation: formattedDate,
        knee_lead: this.knee_lead,
        filename: this.filename,
        filedata: this.filedata,
      };
      axios
          .post("knee_lead.claim.create_video_consultation_claim", requestData)
          .then(response => {
            this.status_code = response.data.status_code;  // Track the status code
          this.showSuccessModal = true;
          this.message = response.data.message;
          })
          .catch(error => {
              console.error("Error saving data:", error);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
          })
          .finally(() => {
            this.loading = false; // Reset loading state after the request completes
          });
    },

    // Close the success modal
    closeSuccessModal() {
      this.showSuccessModal = false;
      window.location.reload();
    }
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(8, 0, 8, 0.4);
  z-index: 2;
  display: grid;
  place-items: center;
}

.modal-container {
  background: white;
  padding: 1rem;
  width: 80vw;
  max-width: 50%;
}
</style>
