<template>
    <div>
        <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content" style="color: #2E8B57;">
            <span class="close" @click="closeModal">&times;</span>
            <h3 style="font-weight: bold;">Changes saved successfully!</h3>
        </div>
    </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="row">
                    <div class="col">
                        <label for="online_consult_request_date_time" class="form-label kx-label-secondary">Online
                            Consultation Done on</label>
                        <VueDatePicker id="online_consult_request_date_time" v-model="online_consult_request_date_time"
                            format="dd/MM/yyyy H:mm"></VueDatePicker>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="Oc_remark" class="kx-label-secondary">Remark</label>
                            <textarea class="form-control" id="online_consultation_remarks"
                                v-model="online_consultation_remarks" rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row"></div>
            </div>
            <div class="col-sm-4">
                <div class="row">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th scope="col">Surgery</th>
                                <th scope="col" colspan="2">Advice</th>
                                <th scope="col" colspan="2">Willing</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th></th>
                                <td style="color: red">Right</td>
                                <td style="color: blue">Left</td>
                                <td style="color: red">Right</td>
                                <td style="color: blue">Left</td>
                            </tr>
                            <tr>
                                <th>SVF</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_svf_right" true-value="1" false-value="0"
                                            id="advise_svf_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_svf_left" true-value="1" false-value="0"
                                            id="advise_svf_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_svf_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_svf_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_svf_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_svf_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>PFO</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_pfo_right" true-value="1" false-value="0"
                                            id="advise_pfo_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_pfo_left" true-value="1" false-value="0"
                                            id="advise_pfo_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_pfo_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_pfo_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_pfo_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_pfo_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>HTO</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_hto_right" true-value="1" false-value="0"
                                            id="advise_hto_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_hto_left" true-value="1" false-value="0"
                                            id="advise_hto_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_hto_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_hto_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_hto_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_hto_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>DFO</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_dfo_right" true-value="1" false-value="0"
                                            id="advise_dfo_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_dfo_left" true-value="1" false-value="0"
                                            id="advise_dfo_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_dfo_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_dfo_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_dfo_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_dfo_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Arthroscopy</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_arthroscopy_right" true-value="1" false-value="0"
                                            id="advise_arthroscopy_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_arthroscopy_left" true-value="1" false-value="0"
                                            id="advise_arthroscopy_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" v-model="online_consult_patient_willingness_arthroscopy_right
                            " true-value="1" false-value="0"
                                            id="online_consult_patient_willingness_arthroscopy_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value="" v-model="online_consult_patient_willingness_arthroscopy_left
                            " true-value="1" false-value="0"
                                            id="online_consult_patient_willingness_arthroscopy_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>TKR</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_tkr_right" true-value="1" false-value="0"
                                            id="advise_tkr_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_tkr_left" true-value="1" false-value="0"
                                            id="advise_tkr_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_tkr_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_tkr_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_tkr_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_tkr_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>THR</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_thr_right" true-value="1" false-value="0"
                                            id="advise_thr_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_thr_left" true-value="1" false-value="0"
                                            id="advise_thr_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_thr_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_thr_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_thr_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_thr_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>PRP</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_prp_right" true-value="1" false-value="0"
                                            id="advise_prp_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_prp_left" true-value="1" false-value="0"
                                            id="advise_prp_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_prp_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_prp_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_prp_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_prp_left" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Other</th>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_other_right" true-value="1" false-value="0"
                                            id="advise_other_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="advise_other_left" true-value="1" false-value="0"
                                            id="advise_other_left" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_other_right" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_other_right" />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-check-input" type="checkbox" value=""
                                            v-model="online_consult_patient_willingness_other_left" true-value="1"
                                            false-value="0" id="online_consult_patient_willingness_other_left" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <button class="btn btn-primary" @click="saveChanges" style="background-color: #007bff;"
                    @mouseover="changeColor" @mouseleave="resetColor">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "OnlineConsultationTab",
    props: {
        msg: String,
    },
    mounted() {
        this.get_online_consultation();
    },
    data() {
        return {
            advise_arthroscopy_left: "",
            advise_arthroscopy_right: "",
            advise_dfo_left: "",
            advise_dfo_right: "",
            advise_hto_left: "",
            advise_hto_right: "",
            advise_pfo_left: "",
            advise_pfo_right: "",
            advise_svf_left: "",
            advise_svf_right: "",
            advise_thr_left: "",
            advise_thr_right: "",
            advise_tkr_left: "",
            advise_tkr_right: "",
            advise_prp_left:"",
            advise_prp_right:"",
            advise_other_left:"",
            advise_other_right:"",
            name: "",
            online_consult_by: "",
            online_consult_date: new Date().toISOString().slice(0, 10),
            online_consult_follow_up_date: "",
            online_consult_next_follow_up_date: "",
            online_consult_patient_willingness_arthroscopy_left: "",
            online_consult_patient_willingness_arthroscopy_right: "",
            online_consult_patient_willingness_dfo_left: "",
            online_consult_patient_willingness_dfo_right: "",
            online_consult_patient_willingness_hto_left: "",
            online_consult_patient_willingness_hto_right: "",
            online_consult_patient_willingness_pfo_left: "",
            online_consult_patient_willingness_pfo_right: "",
            online_consult_patient_willingness_svf_left: "",
            online_consult_patient_willingness_svf_right: "",
            online_consult_patient_willingness_thr_left: "",
            online_consult_patient_willingness_thr_right: "",
            online_consult_patient_willingness_tkr_left: "",
            online_consult_patient_willingness_tkr_right: "",
            online_consult_patient_willingness_prp_left:"",
            online_consult_patient_willingness_prp_right:"",
            online_consult_patient_willingness_other_left:"",
            online_consult_patient_willingness_other_right:"",
            online_consult_request_date_time: null,
            online_consultation_remarks: "",
            showModal: false,
        };
    },
    methods: {
        get_online_consultation: async function () {
            await axios
                .get("knee_lead.api.get_online_consultation_by_knee_lead", {
                    params: {
                        mobile: this.$route.params.id,
                    },
                })
                .then((response) => {
                    this.online_consultation_remarks =
                        response.data.data[0].online_consultation_remarks;
                    this.advise_arthroscopy_left =
                        response.data.data[0].advise_arthroscopy_left;
                    this.advise_arthroscopy_right =
                        response.data.data[0].advise_arthroscopy_right;
                    this.advise_dfo_left = response.data.data[0].advise_dfo_left;
                    this.advise_dfo_right = response.data.data[0].advise_dfo_right;
                    this.advise_hto_left = response.data.data[0].advise_hto_left;
                    this.advise_hto_right = response.data.data[0].advise_hto_right;
                    this.advise_pfo_left = response.data.data[0].advise_pfo_left;
                    this.advise_pfo_right = response.data.data[0].advise_pfo_right;
                    this.advise_svf_left = response.data.data[0].advise_svf_left;
                    this.advise_svf_right = response.data.data[0].advise_svf_right;
                    this.advise_thr_left = response.data.data[0].advise_thr_left;
                    this.advise_thr_right = response.data.data[0].advise_thr_right;
                    this.advise_tkr_left = response.data.data[0].advise_tkr_left;
                    this.advise_tkr_right = response.data.data[0].advise_tkr_right;
                    this.advise_prp_left = response.data.data[0].advise_prp_left;
                    this.advise_prp_right = response.data.data[0].advise_prp_right;
                    this.advise_other_left = response.data.data[0].advise_other_left;
                    this.advise_other_right = response.data.data[0].advise_other_right;
                    this.name = response.data.data[0].name;
                    this.online_consult_by = response.data.data[0].online_consult_by;

                    this.online_consult_exercise_share =
                        response.data.data[0].online_consult_exercise_share;
                    this.online_consult_follow_up_date =
                        response.data.data[0].online_consult_follow_up_date;
                    this.online_consult_next_follow_up_date =
                        response.data.data[0].online_consult_next_follow_up_date;
                    this.online_consult_patient_willingness_arthroscopy_left =
                        response.data.data[0].online_consult_patient_willingness_arthroscopy_left;
                    this.online_consult_patient_willingness_arthroscopy_right =
                        response.data.data[0].online_consult_patient_willingness_arthroscopy_right;
                    this.online_consult_patient_willingness_dfo_left =
                        response.data.data[0].online_consult_patient_willingness_dfo_left;
                    this.online_consult_patient_willingness_dfo_right =
                        response.data.data[0].online_consult_patient_willingness_dfo_right;
                    this.online_consult_patient_willingness_hto_left =
                        response.data.data[0].online_consult_patient_willingness_hto_left;
                    this.online_consult_patient_willingness_hto_right =
                        response.data.data[0].online_consult_patient_willingness_hto_right;
                    this.online_consult_patient_willingness_pfo_left =
                        response.data.data[0].online_consult_patient_willingness_pfo_left;
                    this.online_consult_patient_willingness_pfo_right =
                        response.data.data[0].online_consult_patient_willingness_pfo_right;
                    this.online_consult_patient_willingness_svf_left =
                        response.data.data[0].online_consult_patient_willingness_svf_left;
                    this.online_consult_patient_willingness_svf_right =
                        response.data.data[0].online_consult_patient_willingness_svf_right;
                    this.online_consult_patient_willingness_thr_left =
                        response.data.data[0].online_consult_patient_willingness_thr_left;
                    this.online_consult_patient_willingness_thr_right =
                        response.data.data[0].online_consult_patient_willingness_thr_right;
                    this.online_consult_patient_willingness_tkr_left =
                        response.data.data[0].online_consult_patient_willingness_tkr_left;
                    this.online_consult_patient_willingness_tkr_right =
                        response.data.data[0].online_consult_patient_willingness_tkr_right;
                    this.online_consult_request_date_time =
                        response.data.data[0].online_consult_request_date_time;
                        this.online_consult_patient_willingness_prp_left = 
                        response.data.data[0].online_consult_patient_willingness_prp_left;
                        this.online_consult_patient_willingness_prp_right = 
                        response.data.data[0].online_consult_patient_willingness_prp_right;
                        this.online_consult_patient_willingness_other_left = 
                        response.data.data[0].online_consult_patient_willingness_other_left;
                        this.online_consult_patient_willingness_other_right = 
                        response.data.data[0].online_consult_patient_willingness_other_right;

                    this.online_consult_date = date(
                        response.data.data[0].online_consult_date
                    );
                })
                .catch((error) => { });
        },

        formatDate(date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const hours = String(formattedDate.getHours()).padStart(2, '0');
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
            const milliseconds = String(formattedDate.getMilliseconds()).padStart(3, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        },

        saveChanges: async function () {
            try {
                let updatedFields = {
                    name: this.$route.params.id,
                    advise_arthroscopy_left: this.advise_arthroscopy_left,
                    advise_arthroscopy_right: this.advise_arthroscopy_right,
                    advise_dfo_left: this.advise_dfo_left,
                    advise_dfo_right: this.advise_dfo_right,
                    advise_hto_left: this.advise_hto_left,
                    advise_hto_right: this.advise_hto_right,
                    advise_pfo_left: this.advise_pfo_left,
                    advise_pfo_right: this.advise_pfo_right,
                    advise_svf_left: this.advise_svf_left,
                    advise_svf_right: this.advise_svf_right,
                    advise_thr_left: this.advise_thr_left,
                    advise_thr_right: this.advise_thr_right,
                    advise_tkr_left: this.advise_tkr_left,
                    advise_tkr_right: this.advise_tkr_right,
                    advise_prp_left:this.advise_prp_left,
                    advise_prp_right:this.advise_prp_right,
                    advise_other_left:this.advise_other_left,
                    advise_other_right:this.advise_other_right,
                    online_consult_patient_willingness_arthroscopy_left: this.online_consult_patient_willingness_arthroscopy_left,
                    online_consult_patient_willingness_arthroscopy_right: this.online_consult_patient_willingness_arthroscopy_right,
                    online_consult_patient_willingness_dfo_left: this.online_consult_patient_willingness_dfo_left,
                    online_consult_patient_willingness_dfo_right: this.online_consult_patient_willingness_dfo_right,
                    online_consult_patient_willingness_hto_left: this.online_consult_patient_willingness_hto_left,
                    online_consult_patient_willingness_hto_right: this.online_consult_patient_willingness_hto_right,
                    online_consult_patient_willingness_pfo_left: this.online_consult_patient_willingness_pfo_left,
                    online_consult_patient_willingness_pfo_right: this.online_consult_patient_willingness_pfo_right,
                    online_consult_patient_willingness_svf_left: this.online_consult_patient_willingness_svf_left,
                    online_consult_patient_willingness_svf_right: this.online_consult_patient_willingness_svf_right,
                    online_consult_patient_willingness_thr_left: this.online_consult_patient_willingness_thr_left,
                    online_consult_patient_willingness_thr_right: this.online_consult_patient_willingness_thr_right,
                    online_consult_patient_willingness_tkr_left: this.online_consult_patient_willingness_tkr_left,
                    online_consult_patient_willingness_tkr_right: this.online_consult_patient_willingness_tkr_right,
                    online_consult_patient_willingness_prp_right:this.online_consult_patient_willingness_prp_right,
                    online_consult_patient_willingness_prp_left:this.online_consult_patient_willingness_prp_left,
                    online_consult_patient_willingness_other_left:this.online_consult_patient_willingness_other_left,
                    online_consult_patient_willingness_other_right:this.online_consult_patient_willingness_other_right,
                    online_consult_request_date_time: null,
                    online_consultation_remarks: this.online_consultation_remarks,
                };

                if (this.online_consult_request_date_time) {
                    const formattedDate = new Date(this.online_consult_request_date_time).toISOString();
                    updatedFields.online_consult_request_date_time = this.formatDate(formattedDate);
                }
                await this.update_fields(updatedFields);
                this.showModal = true;
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            } catch (error) {
                console.error('Error saving changes:', error);
            }
        },
        closeModal() {
            this.showModal = false;
        },

        update_fields: async function (updatedFields) {
            try {
                const response = await axios.post("knee_lead.knee_lead_details.update_knee_lead_consultation_tab", updatedFields);
                this.get_online_consultation();
            } catch (error) {
                console.error('Error updating fields:', error);
                throw error;
            }
        },

        changeColor(event) {
            event.target.style.backgroundColor = '#0056b3';
        },
        resetColor(event) {
            event.target.style.backgroundColor = '#007bff';
        }

    },
};
</script>

<style scoped lang="scss">
.modal {
    /* Styles for modal overlay */
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    /* Styles for modal content */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
    /* Ensure the close icon stays in the modal */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}
</style>
